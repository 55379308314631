import React, { Component } from 'react';
import HeaderBack from './HeaderBack';
import { useCookies } from 'react-cookie';

const NotFoundPage = () => {
  const [cookies] = useCookies(['user']);

  let url_redirect = "";
  if (cookies.tab_nav_bussiness==='person') {
    url_redirect='/';
  }else if (cookies.tab_nav_bussiness==='business') {
    url_redirect='/partner';
  }
    return(
      <>
        <HeaderBack urlBack={url_redirect} title={'Página no encontrada'} needLogin={false} />
        <div style={{ width: '100%', height: '100vh', textAlign: 'center', justifyContent: 'center', justifyItems: 'center', justifyContent: 'center', display: 'flex', background: '#FFDFDF' }}>
          <div className='content-page-not-found' style={{ margin: 'auto',  padding: 30 }}>
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', maxWidth: '80%', margin: 'auto' }}>
              <h1 style={{ color: '#fff', fontSize: 140 }}>4</h1>
              <img className='animate__animated animate__bounce animate__infinite animate__slow' alt='Logo pautty.com' style={{ margin: 'auto' }} width={100} height={100} src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/logopauttycircle.png' />
              <h1 style={{ color: '#fff', fontSize: 140 }}>4</h1>
            </div>
            <h1 style={{ fontSize: 60, color: '#f3f3f3' }}>Not Found!</h1>
            <p style={{ color: '#fff' }}>Lo sentimos, no hemos encontrado la página.</p>
          </div>
        </div>
      </>
    )
}

export default NotFoundPage;