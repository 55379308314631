import React, { useEffect, useState, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
// import TagsSearch from './component/TagsSearch';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import { config } from '../configPautty';
import NavBarFooter from '../general/NaBarFooter';
import NavBarTop from '../general/NavBar';
// import { BsSearch } from 'react-icons/bs';
import Form from 'react-bootstrap/Form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BiCurrentLocation } from 'react-icons/bi';

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX;

const Mapa = () => {
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);
    const map = useRef(null);

    const [searchParamsState, setSearchState] = useState(searchParams.get("buscar"));

    const [marketsList, setMarketsList] = useState([]);
    const [countMarketsList, setCountMarketsList] = useState(0);

    const [selectCountry, setSelectCity] = useState(config.types_city_location[0].name);

    const [longitudMap] = useState(config.types_city_location[0].longitud);
    const [latitudMap] = useState(config.types_city_location[0].latitud);
    
    // const [code, setCode] = useState('');
    const navigate = useNavigate();
    // const [spinnerLoading, setSpinnerLoading] = useState(false);
    // const [disabledButton, setDisabledButton] = useState(true);   
    
    useEffect(() => {
      if (searchParamsState!==searchParams.get("buscar")) {
        setSearchState(searchParams.get("buscar"));
        searchMapScreens();
      }
        if (map.current) {
            return
        }
        setLoading(true);
        searchMapScreens();
        getLocation();
    }, [searchParams]);

    const searchMapScreens = () => {
      var nav = new mapboxgl.NavigationControl({visualizePitch: true});
        map.current = new mapboxgl.Map({
            container: 'mapSearch',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [longitudMap, latitudMap],
            zoom: 10,
            
        }).addControl(nav, 'bottom-right');
      for (let i = 0; i < marketsList.length; i++) {
        const element = marketsList[i];
        element.remove();
      }

      setLoading(true);
      fetch(config.urlApi + '/api/v1/search/'+searchParams.get("buscar"), {
        method: "GET"
      })
        .then((response) => response.json())
        .then(data => {
          if (data.length) {
            setCountMarketsList(data.length);
          }else{
            setCountMarketsList(0);
          }
          setSelectCity(0);
            
            for (let i = 0; i < data.length; i++) {
              var markerNewPost = new mapboxgl.Marker({"color":"#f52d3f"});
              markerNewPost.setLngLat([data[i].longitud, data[i].latitud]).setPopup(
                  new mapboxgl.Popup({offset: 25}).setHTML(
                      `<a href="/`+data[i].code_checksum+`"><h4 style="color:#000000">`+data[i].name+`</h4></a><a href="/`+data[i].code_checksum+`"><div class="img-card-home-map" style="background-image: url(`+data[i].photo_tv+`)"; }}></div></a><a style="background:#F7CF57;color:#292929;border-radius:10px;padding:10px;" href="/`+data[i].code_checksum+`">Ver pantalla</a>`
                  )
              ).addTo(map.current);
              setMarketsList([ 
              ...marketsList, 
                markerNewPost
            ]);
            }
            setLoading(false);
            return data;
        })
        .catch(e => {
            setLoading(false);
            return e;
        });
    }

    const getLocation = () => {
      setLoading(true);
      if (!navigator.geolocation) {
        showToastError('Geolocation is not supported by your browser');
      } else {
        try {
              navigator.geolocation.getCurrentPosition((position) => {
                  setPositionMap(position.coords.longitude, position.coords.latitude);
                  setLoading(false);
              }, (err) => {
                showToastError('Unable to retrieve your location: '+err.message);
                setLoading(false);
              });  
              
        } catch (error) {
          alert(error);
        }
      }
    }

    const deleteSearch = () => {
      setSearchState();
      navigate('/');
    }

    const setPositionMap = (longitudTemp, latitudTemp) => {
      map.current.jumpTo({
          center: [longitudTemp, latitudTemp],
          zoom: 11
          });
    }

    const handleChangeCoityuntryMap = (event) => {
      setSelectCity(event.target.value);
      map.current.jumpTo({
        center: [config.types_city_location[event.target.value].longitud, config.types_city_location[event.target.value].latitud],
        zoom: 12
        });
    }

    
      const showToastError = (error) => {
        toast.error(error, {
          position: "bottom-center",
          autoClose: 5000,
          rtl:false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
          });
      }


        return (
            <>
                <NavBarTop></NavBarTop>

                <div className="search-area-full centerMaxContentPage">
                                            
                    {/* <TagsSearch />  */}

                    {searchParamsState&&
                      <div style={{ paddingLeft: 10 }}>
                        <span>Resultados de <i style={{ fontStyle: 'oblique' }}><b>{searchParamsState}</b> ({countMarketsList}) </i></span>
                        <button style={{ border: 'none', borderRadius: 10 }} onClick={()=>deleteSearch()}>Limpiar X</button>
                      </div>
                    }

                        <div className="get-geolocation row" style={{ width: "100%", textAlign: 'center', padding: 10 }}>
                            <div className='col-6' style={{ paddingTop: 7 }}>
                              <BiCurrentLocation color='blue' />
                              <button style={{ border: 'none', background: 'transparent', color: 'blue' }} onClick={getLocation}>Mi ubicación</button> 
                            </div>

                            <Form.Group className='col-6'>
                                <Form.Select className='input-form-pautty' onChange={handleChangeCoityuntryMap} value={selectCountry}>
                                    {config.types_city_location.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                                </Form.Select>
                            </Form.Group>

                        </div>

                        <div id="mapSearch" style={{ width: "100%", height: "68vh", marginBottom: "15px", marginTop: 10}}></div>
                        
{/*                     
                    </div> */}
                </div>

                {loading&&
                    <SpinnerLoadingFixed />
                }

                <ToastContainer />


                <NavBarFooter></NavBarFooter>

            </>
        )

}

export default Mapa;