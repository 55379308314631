import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../configPautty';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import Tv from '../studio-pautty/Tv';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftShort, BsCheckCircleFill, BsRepeat, BsShare } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import ButtonShared from '../utils/ButtonShared';
import { FaLongArrowAltRight } from 'react-icons/fa';

const Pauta = () => {
  // es dispositivo movil
  // const [isDispositivoMovil] = useState(/Mobi/.test(navigator.userAgent));
    let { post } = useParams();
    const [itemPostScreen, setItemPostScreen] = useState();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [elementsPost, setElementsPost] = useState([]);

    const [searchParams] = useSearchParams();
    const [statusPauta, setStatusPauta] = useState(searchParams.get("status"));

    const canvasFunctionRef = useRef();

    useEffect(() => {
      getPostScreensById();
      getElementsPostById();
    }, []);

    const getPostScreensById = () => {
      setLoading(true);
      fetch(config.urlApi + '/api/v1/post-screen/' + post, {
        method: "GET"
      })
        .then((response) => response.json())
        .then(data => {
          if (data.status === 404) {
            showToastError(data.message);
            navigate('/not-found/404');
          } else {
            if (!data.status) {
              setItemPostScreen(data);
              setLoading(false);
            } else {
              showToastError(data.message);
            }
          }
        })
        .catch(e => {
          showToastError("Inténtalo más tarde. ");
          setLoading(true);
        });
    }

    const getElementsPostById = () => {
      fetch(config.urlApi + '/api/v1/element-post/' + post, {
        method: "GET"
      })
        .then((response) => response.json())
        .then(data => {
          if (data.status === 404) {
            showToastError(data.message + " o no tienes permisos para editarlo.");
            navigate('/');
          } else {
            
            if (!data.status) {
              setElementsPost(data);
              setLoading(false);
            } else {
              showToastError("Ocurrió un error al intentar obtener los datos de tu Pauta");
              setLoading(false);
            }
          }
        })
        .catch(e => {
          showToastError("Inténtalo más tarde. ");
          setLoading(false);
        });
    }

    const [showModalSharing, setShowModalSharing] = useState(false);
    const [idPautaSharingCurrent, setIdPautaSharingCurrent] = useState();
    const [titlePautaSharingCurrent, setTitlePautaSharingCurrent] = useState("");

    const handleOpenModalSharing = (id, title_pauta) => {
      setIdPautaSharingCurrent(id);
      setTitlePautaSharingCurrent(title_pauta);
      setShowModalSharing(true)
    };

    const handleCloseModalSharing = () => setShowModalSharing(false);

    const showToastError = (error) => {
      toast.error(error, {
        position: "bottom-center",
        autoClose: 5000,
        rtl: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    }

    const handleConverterToImage = () => {
      canvasFunctionRef.current.handleConverterToImageDownload();      
    }

    const formatDatePublish = (datePublish) => {
      let dateYear = datePublish.split('T')[0].split('-')[0];
      let dateMonth = datePublish.split('T')[0].split('-')[1];
      let dateDay = datePublish.split('T')[0].split('-')[2];
      let dateHour = datePublish.split('T')[1].split('.')[0].split(':')[0];
      let dateMin = datePublish.split('T')[1].split('.')[0].split(':')[1];
      return dateDay+' de '+converterNumberToMonth(dateMonth)+" de "+dateYear+' a las '+dateHour+':'+dateMin;
    }

    const converterNumberToMonth = (monthDate) => {
      if (monthDate==='01') {
       return 'Enero'; 
      }else if (monthDate==='02') {
        return 'Febrero'; 
       }else if (monthDate==='03') {
        return 'Marzo'; 
       }else if (monthDate==='04') {
        return 'Abril'; 
       }else if (monthDate==='05') {
        return 'Mayo'; 
       }else if (monthDate==='06') {
        return 'Junio'; 
       }else if (monthDate==='07') {
        return 'Julio'; 
       }else if (monthDate==='08') {
        return 'Agosto'; 
       }else if (monthDate==='09') {
        return 'Septiembre'; 
       }else if (monthDate==='10') {
        return 'Octubre'; 
       }else if (monthDate==='11') {
        return 'Noviembre'; 
       }else if (monthDate==='12') {
        return 'Diciembre'; 
       } 
    }

    const StatusComponent = ({status_id}) => {
      
      let animatedStyle = 'animate__animated animate__backInLeft';
      return   <div className='tabs-medios tabs-medios-pautas scroll-status-pauta' style={{ width: window.screen.width-20 }}>

                  <label className={status_id==='0'?'active ':''+animatedStyle}>
                      {parseInt(status_id)>0&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      Registrado
                      {' '}<FaLongArrowAltRight />
                    </label>
                  <label className={status_id==='1'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)>1&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      Recibido
                      {' '}<FaLongArrowAltRight />
                    </label>
                  <label className={status_id==='2'?'active '+animatedStyle:''+animatedStyle}>
                    {parseInt(status_id)>2&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      Pago Confirmado
                      {' '}<FaLongArrowAltRight />
                    </label>
                  <label className={status_id==='3'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)>3&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      Aprobación de Diseño
                      {' '}<FaLongArrowAltRight />
                    </label>
                  <label className={status_id==='4'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)>4&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      En proceso de Publicación
                      {' '}<FaLongArrowAltRight />
                    </label>
                  <label className={status_id==='5'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)>5&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      Publicado
                      {' '}<FaLongArrowAltRight />
                    </label>  
                    <label className={status_id==='6'?'active '+animatedStyle:''+animatedStyle}>
                      {parseInt(status_id)===6&&
                        <BsCheckCircleFill color='#31D613' size={25} style={{ paddingRight: 5 }} />
                      }
                      Finalizado
                    </label>                  
              </div>
    }
   
    return (
        <div style={{ background: '#C8C7C7', minHeight: '100vh', justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>

          <button className="top-to-breadcrumb-show" onClick={() => {
                  navigate(-1);
              }}>
              <BsArrowLeftShort size={25} />
          </button>


                      
          {itemPostScreen ?
              <div style={{ margin: 'auto', position: 'relative' }} >
                  
                <Tv preview={true} template={itemPostScreen.option_style} elements={elementsPost} itemPostScreen={itemPostScreen} widthPreview={itemPostScreen.width_screen_active} heightPreview={itemPostScreen.height_screen_active} ></Tv>
              </div>
            :
            <SpinnerLoadingFixed />
          }
          
          {!loading &&
              <button onClick={()=>handleOpenModalSharing(itemPostScreen.post_screen_id, itemPostScreen.name)} style={{ color: '#292929', background: '#eaeaea', position: 'absolute', right: 10, top: 10, zIndex: 9999 }} className='btn btn-default button-top-editor'> Compartir {' '} <BsShare color='#292929' size={25} /> </button>
            }
          
          <div style={{ position: 'absolute', left: 0, bottom: 0, width: '100%' }}>
            <div style={{ width: '100%', position: 'relative', left: 0, bottom: 0, zIndex: 99999, margin: 'auto', maxWidth: 600 }}>
              {/* {!loading &&
                <div>
                    <div style={{ position: 'fixed', right: 20, bottom: 20, zIndex: 999 }}>
                      <button className='sombras-tv-card' onClick={()=>navigate('/chat/'+itemPostScreen.post_screen_id)} style={{ border: 'none', padding: '5px 20px 5px 20px', borderRadius: 15, background: '#F7CF57', color: '#292929' }}>
                        <BsChat size={30} /> Mensajes
                      </button>
                    </div>
                  </div>
              } */}

              {/* {!loading &&
                <div>
                    <div style={{ position: 'absolute', left: 20, bottom: 20, zIndex: 999 }}>
                      <button className='sombras-tv-card' onClick={handleConverterToImage} style={{ border: 'none', padding: '5px 20px 5px 20px', borderRadius: 15, background: '#F7CF57', color: '#292929' }}>
                        <BsDownload size={30} /> Descargar
                      </button>
                    </div>                    
                  </div>
              }   */}
              
            </div>  
          </div>    

          {itemPostScreen &&
              <div>
                {statusPauta==='true'&&
                    <div className='show-status-pauta'>

                      <div className='content-update-status-button'>
                        <button onClick={()=>getPostScreensById()}><BsRepeat /> Actualizar</button>
                      </div>

                      <StatusComponent status_id={itemPostScreen.status_pauta}  />
                    </div>
                  }
              </div>      
          }

          <Modal
            show={showModalSharing}
            onHide={handleCloseModalSharing}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Compartir en redes sociales</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ButtonShared id_link={idPautaSharingCurrent} title={titlePautaSharingCurrent} type_shared={'pauta'} />
            </Modal.Body>
            </Modal>


          <ToastContainer />
        </div>
    )
}

export default Pauta;