import React, { useState } from 'react';
import Tv from '../studio-pautty/Tv';
import { BsChatFill, BsFillPenFill, BsPencilFill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { config } from '../configPautty';
import { ToastContainer, toast } from 'react-toastify';
import { FcCustomerSupport } from 'react-icons/fc';
import { useCookies } from 'react-cookie';
import { Form } from 'react-bootstrap';

const PautaItem = ({ item, index, userPremiumMessage, pautar, screen, isPartner }) => {
    const navigate = useNavigate();
    const [cookies] = useCookies(['user']);

    const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
    const updateStatusPauta = (event) => {
        setLoadingChangeStatus(true);
        fetch(config.urlApi + '/api/v1/change-status-pauta/', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            },
            body: JSON.stringify({
                post_screen_id: item.post_screen_id,
                screen_id: parseInt(screen),
                status_pauta: event.target.value
            })
        })
            .then((response) => response.json())
            .then(data => {
                if (!data.status) {
                    item.status_pauta=event.target.value;
                } else {
                    showToastError(data.message);
                }
                setLoadingChangeStatus(false);
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                setLoadingChangeStatus(false);
                return e;
            });
    }

    
    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

        return (
            <div key={index} className='item-card-list-pautas-status'>
                <div style={{justifyContent: 'center', justifyItems: 'center' }}>
                    <button title='Ver Pauta' onClick={()=>{navigate('/pauta/'+item.post_screen_id+"?status=true")}} key={item.post_screen_id} style={{ position: 'relative', padding: 20, border: 'none', margin: 0, borderRadius: 5 }} >
                        {/* <Tv template={item.option_style} itemPostScreen={item} widthPreview={item.width_screen_active/1.3} heightPreview={item.height_screen_active/1.3} ></Tv>        */}
                        <span>Pauta #{item.post_screen_id}</span>
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>
                        {item.type_service_app!=='influencer'&&
                            <span className='card-item-dimensions'>Dimensión: {item.width_screen_active}m x {item.height_screen_active}m</span>
                        }
                        

                    </button>

                    {!isPartner&&
                    <div className='content-buttons-item-card row'>
                        <div className='col-6'>
                            <button className='btn'>{config.list_status_pauta[item.status_pauta].name}</button>
                        </div>
                        <div className='col-6'>
                            {(item.status_pauta!=='3' && item.status_pauta!=='4' && item.status_pauta!=='5')&&
                                <button className='btn btn-info' onClick={()=>{navigate('/editar-pantalla/' + item.screen_id + '/editar-contenido/' + item.post_screen_id)}}><BsFillPenFill />{' '}Editar</button>
                            }
                        </div>
                    </div>
                    }

                    {isPartner&&
                        <div className='content-buttons-item-card'>
                            {loadingChangeStatus ?
                                <div className="d-flex justify-content-center" style={{ float: 'right', paddingTop: 7 }}>
                                    <span className="spinner-border spinner-border-sm button-sppiner-pauty" role="status" aria-hidden="true"></span>
                                </div>
                                :
                                <>
                                {item.type_service_app!=='valla-digital'?
                                    <Form.Group>
                                        <Form.Select className='input-form-pautty sombras-tv-card' onChange={updateStatusPauta} value={item.status_pauta}>
                                            {config.list_status_pauta.map((status) => <option key={status.id} value={status.id}>{status.name}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                :
                                <span>{config.list_status_pauta[item.status_pauta].name}</span>
                                }
                                
                                    
                                </>
                            }
                        </div>
                    }
                </div>
                <hr></hr>
                <ToastContainer />
            </div>
        )    
    
    
}

export default PautaItem;