import React, { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { config } from '../../configPautty';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import { BsFillPatchCheckFill, BsCircleFill } from 'react-icons/bs';
import { FcAutomotive } from 'react-icons/fc';
import { MdLocationOn } from 'react-icons/md';
let dollarIndianLocale = Intl.NumberFormat('es-CO');

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX;

const Valla = ({ valla }) => {
    const [showModalImage, setShowwModalImage] = useState(false);

    const handlewModalImageClose = () => setShowwModalImage(false);
    const handlewModalImageShow = () => setShowwModalImage(true);

    useEffect(() => {
        return () => null;
    }, []);

     // es dispositivo movil
    const [isDispositivoMovil] = useState(/Mobi/.test(navigator.userAgent));
    var widthValla = window.screen.width/5;
    var heigthhValla = widthValla/2.5;
    if (isDispositivoMovil) {
        widthValla = window.screen.width/1.3;
        heigthhValla = widthValla/2.5;
    }

    return (
        <>

        <div className='row' style={{ margin: 0 }}>

            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' style={{ margin: 0, padding: 0 }} onClick={handlewModalImageShow}>
                <img height={'auto'} width={'100%'} src={valla.photo_tv} alt={'Imagen pautty ' + valla.name} />
            </div>


            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' style={{ maxWidth: 800, margin: 'auto', paddingBottom: 30 }}>

                <div style={{ paddingLeft: 30, paddingRight: 30, position: 'relative    ' }}>

                {valla.owner_company!==''&&
                    <span className='text-company-card-home-show'>
                        By <b>{valla.owner_company}</b>
                    </span>
                }

                    <div className='text-title-valla'>
                        {valla.valid_screen ?
                            <h2>{valla.name} {' '}
                                <span title='Verificado por el equipo de Pautty'>
                                    <BsFillPatchCheckFill color='#49ADF4' />
                                </span>
                            </h2>
                            :
                            <h2>{valla.name}
                            </h2>
                        }

                    {(valla.time_price !== '' && valla.time_price !== undefined && valla.price !== "" && valla.price !== "0") &&
                        <div>
                            <span className='price-card-home'>${dollarIndianLocale.format(valla.price)}</span>
                            <span className='text-price-extra-home'>{config.list_code_plan_payments_pautty[parseInt(valla.time_price)].name}</span>
                        </div>
                    }

                    </div>
                    {/* <div style={{ textAlign: 'center', width: '100%' }}>
                        <p style={{ color: '#292929' }}>{valla.number_visits} Visualizaciones</p>
                    </div> */}

                  

                    <div>

                        <div className='row'>

                            <Modal
                                show={showModalImage}
                                dialogClassName="modal-90w"
                                centered
                                onHide={handlewModalImageClose}>
                                <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                                    <Modal.Title style={{ width: '100%', textAlign: 'center' }}>{valla.name}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img src={valla.photo_tv} alt={valla.name} width={'100%'} />
                                </Modal.Body>
                            </Modal>
                            
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' style={{ textAlign: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>
                                {(valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') &&
                                    <div className='row' style={{ position: 'relative' }}>
                                        <div className='col-12' style={{ justifyContent: 'center', justifyItems: 'center' }}>
                                            <div style={{ fontSize: 10, margin: 'auto', justifyContent: 'center', justifyItems: 'center', display: 'flex', border: '2px solid #404040', width: widthValla, height: heigthhValla, background: '#F7CF57' }}>
                                            {/* <div style={{ fontSize: 10, margin: 'auto', border: '2px solid #404040', backgroundImage: 'url(https://statics-gridwel.s3.us-east-2.amazonaws.com/imagePost/1_user_image_post_202406290903121909014050005.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', width: widthValla, height: heigthhValla }}> */}
                                                <h3 className='animate__animated animate__infinite animate__heartBeat' style={{ color: '#dc3545', margin: 'auto' }}><b>PAUTA<br></br> AQUÍ</b></h3>
                                                
                                            </div>
                                            <div style={{ marginTop: -10 }}>
                                                <img alt='valla' width={widthValla} src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/base-valla-largo.png' />
                                            </div>

                                            <div style={{ position: 'relative', marginTop: -10 }}>
                                                <div style={{ position: 'absolute', fontSize: 10, left: '53%', top: '10%' }}>
                                                    {(valla.poste_height !== 0 && valla.poste_height !== '' && valla.poste_height !== '0' && valla.poste_height !== undefined) &&
                                                        <span>{valla.poste_height}m</span>
                                                    }
                                                </div>
                                                <img alt='valla' width={window.screen.width/5} height={50} src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/base-valla-poste.png' />                                                

                                                <div>
                                                    <span>
                                                        {valla.width_screen_active}m x {valla.height_screen_active}m
                                                    </span>
                                                </div>
                                                    
                                            </div>

                                        </div>

                                    </div>
                                }
                            </div>

                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 info_valla' style={{ textAlign: 'center', justifyContent: 'center', justifyItems: 'center' }}>

                                {(valla.type_service_app === 'valla' || valla.type_service_app === 'valla-digital') &&
                                    <>
                                        {valla.available ?
                                            <span style={{ color: '#07bc0c' }}>Disponible <BsCircleFill color='#07bc0c' /></span>
                                            :
                                            <span style={{ color: '#e74c3c' }}>No Disponible <BsCircleFill color='#e74c3c' /></span>
                                        }
                                    </>
                                }

                                <div style={{ padding: 10 }}>
                                    <span>La Valla tiene <b>{valla.number_faces_valla}</b> caras</span>
                                </div>

                                {valla.address_location !== '' &&
                                    <div style={{ width: '100%', textAlign: 'center', padding: 5 }}>
                                        <p><MdLocationOn /> <b>Dirección: </b>{valla.address_location} </p>
                                    </div>
                                }
                                {(valla.people_pass !== '' && valla.people_pass !== '0') &&
                                    <div style={{ width: '100%', textAlign: 'center', padding: 5 }}>
                                        <p><FcAutomotive /> <b>Tránsito: </b>{valla.people_pass} </p>
                                    </div>
                                }
                                


                                <div style={{ width: '100%', textAlign: 'center', padding: 5 }}>
                                    <p><b>Ubicado en: </b>{valla.type_business} </p>
                                </div>

                                {valla.type_service_app === 'valla-digital'?
                                    <span style={{ background: '#19a7a4', color: '#fff', padding: '3px 28px 3px 28px', borderRadius: 5 }}>Valla Digital</span>
                                    :
                                    <span style={{ background: '#dcdcdc', color: '#fff', padding: '3px 28px 3px 28px', borderRadius: 5 }}>Valla No Digital</span>
                                }
                                
                            </div>
                        </div>

                    </div>


                </div>


            </div>

        </div>
            
            <ToastContainer />
        </>
    )

}

export default Valla;