import React from 'react';

const SpinnerLoadingFixed = () => {
    return (
        <div>
            <div style={{ background: '#fff', height: '100vh', width: "100%", position: 'fixed', top: 0, left: 0, zIndex: 99999, opacity: 0.9}}>
            </div>
            <div style={{ height: '100%', width: '100%', zIndex: 9999999, position: 'fixed', textAlign: 'center', verticalAlign: 'middle', top: 0, left: 0, display: 'flex', justifyContent: 'center', justifyItems: 'center'}}>
                <h1 className="text-login-title" style={{ fontFamily: "Rampart One", margin: 'auto' }}>
                        <img alt='Logo pautty' width={211} src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/logopauttycircle.png' />
                    <br></br>
                    <b>Pautty</b>
                    <p></p>
                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                </h1>
            </div>
        </div>
    )
}

export default SpinnerLoadingFixed;