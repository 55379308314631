import React, { useEffect, useState } from 'react';
import { config } from '../configPautty';
import { deleteCookies } from '../deleteCookies';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import { BsCollection, BsDisc, BsEasel, BsEye, BsFacebook, BsFillInfoCircleFill, BsFillPatchCheckFill, BsLinkedin, BsPersonBadge, BsShare, BsTv, BsTwitter, BsWhatsapp } from 'react-icons/bs';
import { Carousel, Modal } from 'react-bootstrap';
import NavBarTop from '../general/NavBar';
import NavBarFooter from '../general/NaBarFooter';
import { MdLocationOn } from 'react-icons/md';
import ItemCard from './component/ItemCard';
import ButtonShared from '../utils/ButtonShared';
let dollarIndianLocale = Intl.NumberFormat('es-CO');

const Home = () => {
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [pageCurrent, setPageCurrent] = useState(0);
    const [itemPostScreen, setItemPostScreen] = useState();
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [showButtonGet, setShowButtonGet] = useState(true);

    const [loading, setLoading] = useState(true);

    const [tabShowGetScreens, setKeyTabShowGetScreens] = useState(cookies.tab_current!==undefined?cookies.tab_current:'valla');

    const [showModalSharing, setShowModalSharing] = useState(false);
    const [idCodeScreenSharingCurrent, setIdCodeScreenSharingCurrent] = useState();
    const [titleScreenSharingCurrent, setTitleScreenSharingCurrent] = useState("");

    const handleOpenModalSharing = (code_screen, title_screen) => {
        setIdCodeScreenSharingCurrent(code_screen);
        setTitleScreenSharingCurrent(title_screen);
        setShowModalSharing(true)
    };
    const handleCloseModalSharing = () => setShowModalSharing(false);

    useEffect(() => {
        if (cookies.tab_nav_bussiness==='business') {
            navigate('/partner/');
        }
        if (loading) {
            getListScreensNext(tabShowGetScreens, true);
        }
    }, [loading, tabShowGetScreens])

    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }
    const toggleFullScreen = (elem, status) => {

        if (status) {
          if (elem.requestFullScreen) {
            elem.requestFullScreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      }

    const [valueSearch] = useState(searchParams.get("buscar"));

    const getListScreensNext = (medio, isIncrement) => {
        setCookies('SecondsOTP', 0, { path: '/' });
        setSpinnerLoading(true);
        let urlFilters = "";
        if (isIncrement) {
                urlFilters = config.urlApi + '/api/v1/list-screen-all/' + medio + '?page=' + pageCurrent+"&buscar="+valueSearch;
        }else{            
            urlFilters = config.urlApi + '/api/v1/list-screen-all/' + medio + '?page=0&buscar='+valueSearch;
        }
        fetch(urlFilters, {
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            }
        })
            .then((response) => response.json())
            .then(data => {
                setLoading(false);
                if (data.status === 401) {
                    deleteCookies();
                    navigate('/login');
                } else {
                    if (pageCurrent === 0) {
                        setItemPostScreen(data);
                    } else {
                        if (!data.status) {
                            setItemPostScreen(itemPostScreen => itemPostScreen.concat(data));
                        } else {
                            // showToastError(data.message);
                            setShowButtonGet(false);
                            setPageCurrent(0);
                        }
                    }
                }

                setSpinnerLoading(false);
                if (isIncrement) {
                    setPageCurrent(pageCurrent + 1);                    
                }
                return data;
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                setSpinnerLoading(false);
                console.log(e.message);
                return e;
            });
    }

    const [listLastImages] = useState(config.images_slider_home);

    // const changeValueSearch = (event) => {
    //     setValueSearch(event.target.value);
    // }

    // const formLinkValueSearch = (event) => {
    //     event.preventDefault();
    //     if (valueSearch!==undefined && valueSearch!=='null' && valueSearch!=='' && valueSearch!=='undefined') {
    //         setItemPostScreen([]);
    //         getListScreensNext(tabShowGetScreens, false);   
    //     }
    // }

    // const searchByBusiness = () => {
    //     setItemPostScreen([]);
    //     getListScreensNext(tabShowGetScreens, false);
    // }

    

    const handleChangeTabKey = (medio) => {
        setItemPostScreen([]);
        setShowButtonGet(true);
        setPageCurrent(1);
        getListScreensNext(medio, false);
        setKeyTabShowGetScreens(medio);
    }

    return (
        <div>
        <NavBarTop isHome={true}></NavBarTop>
        <div className='contentIndexNavBar' style={{ background: '#fff' }}>
        
        <div style={{ marginTop: -66, background: '#fff' }}>
            <Carousel indicators={false} controls={false}>
                {listLastImages.map((item) => {
                    return <Carousel.Item key={item.id}>
                                <img
                                    className="d-block w-100"
                                    src={item.image}
                                    alt={item.name}
                                    width={'100%'}
                                    height={500}
                                />
                                <Carousel.Caption style={{ justifyContent: 'center', justifyItems: 'center', top: '30%' }}>
                                    <div>
                                        <b><h1  className='carousel-pauta-content'>{item.name}</h1></b>
                                        <span>{item.description}</span>
                                        {/* <p style={{ padding: 20 }}> */}
                                            {/* <button onClick={()=>{
                                                navigate(item.url);
                                            }} className='color-bottons-start animate__animated animate__delay-2s animate__tada animate__repeat-2 infinite'>
                                                {item.textButton}
                                            </button> */}
                                        {/* </p> */}

                                        {/* <form onSubmit={formLinkValueSearch}>
                                            <input onChange={changeValueSearch} value={valueSearch} type="text" className='input-search-home' placeholder='¿Dónde quieres publicar?' />
                                            <button style={{ border: 'none', background: 'transparent' }} type='submit' title='¿Dónde quieres publicar?'><i className='button-search-input-home'><BsSearch size={30} color='#fff' /></i></button>
                                        </form> */}
                                        
                                        {/* <div className="single mb-20 centerMaxContentPage" style={{ paddingTop: 0, paddingLeft: 5, overflowX: 'auto', whiteSpace: 'nowrap', paddingBottom: 5, background: 'transparent' }}>
                                            <div className="search-tags" style={{ display: 'flex' }}>
                                                {config.types_screen_location_valla.map((item) => {
                                                    return(
                                                        <div key={item.id} style={{ margin: 'auto' }}>
                                                            {item.name!=='Ninguno'&&
                                                                <button onClick={()=>{setValueSearch(item.name);searchByBusiness();}} className='animate__animated animate__bounceInRight animate__slower' style={{ cursor: 'pointer', background: 'transparent', border:' none', color: '#fff' }} title={'Buscar pantallas en '+item.name} key={item.id}>{item.name}{' '}</button>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div> */}
                                        
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                })}
            </Carousel>
        </div>

        <div style={{ margin: 'auto', maxWidth: '800px', paddingTop: 20 }}>

            <div style={{ textAlign: 'center' }}>
                <h5>¿Dónde quieres Pautar?</h5>
            </div>

            <div className='tabs-medios'>

                <div>
                    <button onClick={()=>{handleChangeTabKey('influencer');setCookies('tab_current', 'influencer', { path: '/' });}} className={tabShowGetScreens==='influencer'?'active ':''}>
                        <BsPersonBadge />{' '}
                        Páginas
                    </button>
                </div>
                {/* <div>
                    <button onClick={()=>{handleChangeTabKey('valla-digital');setCookies('tab_current', 'valla-digital', { path: '/' });}} className={tabShowGetScreens==='valla-digital'?'active ':''}>
                        <BsCollection />{' '}
                        Vallas Digitales
                    </button>
                </div> */}
                <div>
                    <button onClick={()=>{handleChangeTabKey('valla');setCookies('tab_current', 'valla', { path: '/' });}} className={tabShowGetScreens==='valla'?'active ':''}>
                        <BsEasel />{' '}
                        Vallas
                    </button>
                </div>
                
                {/* <div>
                    <button onClick={()=>{handleChangeTabKey('television');setCookies('tab_current', 'television', { path: '/' });}} className={tabShowGetScreens==='television'?'active ':''}>
                        <BsTv />{' '}
                        Televisión
                    </button>
                </div>
                <div>
                    <button onClick={()=>{handleChangeTabKey('radio');setCookies('tab_current', 'radio', { path: '/' });}} className={tabShowGetScreens==='radio'?'active ':''}>
                        <BsDisc />{' '}
                        Radio
                    </button>
                </div> */}
                
            </div>
        </div>

            {!loading &&
                <section className="feature-product" style={{ maxWidth: '100%', justifyItems: 'center', justifyContent: 'center', margin: 'auto' }}>
                    <div className="product-all">

                        {itemPostScreen.length>0 ?
                            <div className="row">
                                {itemPostScreen.map((item) => {
                                        return <ItemCard key={item.screen_id} item={item} tabShowGetScreens={tabShowGetScreens} handleOpenModalSharing={handleOpenModalSharing} buttonFull={()=>toggleFullScreen(document.body, true)} />
                                    })}
                            </div>
                            :
                            <div style={{ width: '100%', textAlign: 'center', paddingTop: 10 }}>
                                <BsFillInfoCircleFill color='#838080' size={50} />
                                <h3 style={{ paddingTop: 10, color: '#838080' }}>No hay resultados con: "{valueSearch}"</h3>
                            </div>
                        }
                        <div className="row" style={{ paddingBottom: 100, paddingTop: 30 }}>
                            <div className="col-12 mt-10 text-center">
                                {(!spinnerLoading && showButtonGet) &&
                                    <button onClick={()=>{getListScreensNext(tabShowGetScreens, true)}} className="button-12" style={{ cursor: 'pointer', border: 'none' }}>
                                        Ver más
                                    </button>
                                }
                                {spinnerLoading &&
                                    <div style={{ zIndex: 9999999, textAlign: 'center', verticalAlign: 'middle', top: 0, left: 0, display: 'flex', justifyContent: 'center', justifyItems: 'center'}}>
                                        <h1 className="text-login-title" style={{ fontFamily: "Rampart One", margin: 'auto' }}>
                                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                                        </h1>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </section>
            }
            {loading &&
                <>
                    <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                        <span className="spinner-border spinner-border-sm button-sppiner-pauty"
                            role="status" aria-hidden="true"></span>
                    </div>
                    <SpinnerLoadingFixed />
                </>
            }

            <ToastContainer />
            </div>

            <Modal
                show={showModalSharing}
                onHide={handleCloseModalSharing}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                    <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Compartir en redes sociales</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ButtonShared id_link={idCodeScreenSharingCurrent} title={titleScreenSharingCurrent} type_shared={'valla'} />
                </Modal.Body>
                </Modal>
                
            <NavBarFooter></NavBarFooter>
        </div>
    )
}

export default Home;