import React, { useEffect, useState } from 'react';
import { config } from '../configPautty';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { FcAddImage, FcAddRow } from "react-icons/fc";
import { BsTrashFill, BsPlus, BsXLg, BsEasel, BsFillInfoCircleFill, BsFillPatchCheckFill, BsCollection, BsTv, BsDisc, BsPersonBadge, BsEye, BsShare, BsFacebook, BsTwitter, BsWhatsapp, BsLinkedin } from "react-icons/bs";
import { MdLocationOn } from 'react-icons/md';

import Modal from 'react-bootstrap/Modal';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import { Link, useNavigate } from 'react-router-dom';
import { deleteCookies } from '../deleteCookies';
import { Form } from 'react-bootstrap';
import NavBarTop from '../general/NavBar';
import ItemCard from '../home/component/ItemCard';
import ButtonShared from '../utils/ButtonShared';
let dollarIndianLocale = Intl.NumberFormat('es-CO');


const HomePartner = () => {
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();

    const [itemScreen, setItemScreen] = useState();
    const [showModalDeleteScreen, setShowModalDeleteScreen] = useState(false);
    const [idScreenCurrentSelect, setIdScreenCurrentSelect] = useState();

    const [spinnerLoadingDeleting, setSpinnerLoadingDeleting] = useState(false);
    
    const [disabledButton, setDisabledButton] = useState(false);
    const [spinnerLoading, setSpinnerLoading] = useState(false);

    const [loading, setLoading] = useState(true);

    // Valla
    const [nameValla, setNameValla] = useState('');
    const [typeValla, setTypeValla] = useState();
    const [heightValla, setHeightValla] = useState('');
    const [widthtValla, setWidthtValla] = useState('');
    const [sizePosteValla, setSizePosteValla] = useState("1");
    const [numberFacesValla, setNumberFacesValla] = useState(1);
    const [audiencia, setAudiencia] = useState('');
    const [programa, setPrograma] = useState('');

    const [showVincular, setShowVincular] = useState(false);

    const [codeCurrentIdScreen, setCodeCurrentIdScreen] = useState("");

    const [isReaderCode, setIsReaderCode] = useState(false);
    const handleReaderStop = () => setIsReaderCode(false);

    const handleChangeCodeScreen = (event) => {
        setCodeCurrentIdScreen(event.target.value);
    }

    const handleCloseVincular = () => setShowVincular(false);
    const handleShowVincular = () => setShowVincular(true);

    const [tabTypeServiceMedio, setKeyTabTypeServiceMedio] = useState(cookies.tab_current);
    const [tabShowGetScreens, setKeyTabShowGetScreens] = useState(cookies.tab_current);

    const [showFormMedio, setShowFormMedio] = useState(false);

    useEffect(() => {
        if (loading) {
            getListScreens(tabShowGetScreens);
        }
    }, [loading, tabShowGetScreens])

    const getListScreens = (medio) => {
        setLoading(true);
        fetch(config.urlApi + '/api/v1/list-my-screen/'+medio, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            }
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status === 401) {
                    deleteCookies();
                    navigate('/login');
                } else {
                    if (!data.status) {
                        setItemScreen(data);
                    } else {
                        setItemScreen();
                    }
                }
                setLoading(false);
                setSpinnerLoadingDeleting(false);
                return data;
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const showToastSuccess = (message) => {
        setCookies('SecondsOTP', 0, { path: '/' });
        toast.success(message, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const handleSubmitActiveScreen = () => {

        if (codeCurrentIdScreen.length < 4 || codeCurrentIdScreen === undefined) {
            showToastError("Ingresa el código completo");
            return
        }

        fetch(config.urlApi + '/api/v1/active-screen-by-code' , {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            },
            body: JSON.stringify({
                code_checksum: codeCurrentIdScreen
            })
        })
            .then((response) => response.json())
            .then(data => {
                console.log("data.status");
                console.log(data.status);
                if (data.status === 200) {
                    navigate('/pautas/'+codeCurrentIdScreen);
                    showToastSuccess("La pantalla ha sido vinculada.");
                } else{
                    showToastError("Error al buscar la pantalla o la pantalla ya ha sido activada.");
                }
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    const handleSubmitActiveScreenQR = (code) => {

        code = code.replace("https://partner.pautty.com/pautas/", '')

        if (code.length < 4 || code === undefined) {
            showToastError("Ingresa el código completo");
            return
        }

        setIsReaderCode(true);

        fetch(config.urlApi + '/api/v1/active-screen-by-code' , {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            },
            body: JSON.stringify({
                code_checksum: code
            })
        })
            .then((response) => response.json())
            .then(data => {
                console.log("data.status");
                console.log(data.status);
                if (data.status === 200) {
                    // navigate('/pautas/'+code);
                    showToastSuccess("La pantalla ha sido vinculada.");
                    window.location.href = '/pautas/'+code;
                } else{
                    showToastError("Error al buscar la pantalla o la pantalla ya ha sido activada.");
                    window.location.href = '/';
                }
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    const [showModalSharing, setShowModalSharing] = useState(false);
    const [idCodeScreenSharingCurrent, setIdCodeScreenSharingCurrent] = useState();
    const [titleScreenSharingCurrent, setTitleScreenSharingCurrent] = useState("");
    const handleOpenModalSharing = (code_screen, title_screen) => {
        setIdCodeScreenSharingCurrent(code_screen);
        setTitleScreenSharingCurrent(title_screen);
        setShowModalSharing(true)
    };
    const handleCloseModalSharing = () => setShowModalSharing(false);    

    const handleShowModalDeleteScreen = (idScreen) => {
        setIdScreenCurrentSelect(idScreen);
        setShowModalDeleteScreen(true);
    }

    const DeleteScreen = () => {
        setShowModalDeleteScreen(false);
        setSpinnerLoadingDeleting(true);
        
        fetch(config.urlApi + '/api/v1/screen/' + idScreenCurrentSelect, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            }
        })
            .then((response) => response.json())
            .then(data => {
                if (!data.status) {
                    showToastSuccess("Pantalla eliminada.");
                    setIdScreenCurrentSelect(null);
                    setShowModalDeleteScreen(false);
                    getListScreens(tabShowGetScreens);
                } else {
                    showToastError(data.message);
                    if (data.status === 401) {
                        deleteCookies();
                        navigate('/login');
                    }
                }
                return data;
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. "+e);
                setSpinnerLoadingDeleting(false);
                return e;
            });
    }

    const [showsetShowOptionsNewScreen, setShowOptionsNewScreen] = useState(false);

    const handleCloseOptionsNewScreen = () => setShowOptionsNewScreen(false);
    // const handleShowOptionsNewScreen = () => setShowOptionsNewScreen(true);

    
    // Vallas
    const handleChangeNameValla = (event) => {
        setNameValla(event.target.value);
    }
    const handleChangeTypeValla = (event) => {
        setTypeValla(event.target.value);
    }
    const handleChangeHeightValla = (event) => {
        setHeightValla(event.target.value);
    }
    const handleChangeWidthValla = (event) => {
        setWidthtValla(event.target.value);
    }
    const handleChangeSizePosteValla = (event) => {
        setSizePosteValla(event.target.value);
    }
    const handleChangeNumberFacesValla = (event) => {
        setNumberFacesValla(event.target.value);
    }
    const handleChangeAudiencia = (event) => {
        setAudiencia(event.target.value);
    }
    const handleChangePrograma = (event) => {
        setPrograma(event.target.value);
    }

    const handleSubmitNewMedioPautas = event => {
        event.preventDefault();
        setDisabledButton(true);
        setSpinnerLoading(true);
        if ( nameValla === '') {    
            showToastError("Agrega un nombre.");
            setSpinnerLoading(false);
            setDisabledButton(false);
            return null;
        }

        if (tabTypeServiceMedio==='valla' || tabTypeServiceMedio==='valla-digital') {
            if ( widthtValla === '') {    
                showToastError("Agrega el ancho de la Valla.");
                setSpinnerLoading(false);
                setDisabledButton(false);
                return null;
            }
            if ( heightValla === '') {    
                showToastError("Agrega el alto de la Valla.");
                setSpinnerLoading(false);
                setDisabledButton(false);
                return null;
            }    

            if ( typeValla === 'Ninguno' || typeValla === '' || typeValla === undefined) {    
                showToastError("Selecciona ubicación donde se encuentra la pantalla.");
                setSpinnerLoading(false);
                setDisabledButton(false);
                return null;
            }

            if ( numberFacesValla === '' || numberFacesValla === undefined || numberFacesValla === 0 || numberFacesValla>3) {    
                showToastError("La cantidad de caras no es correcto.");
                setSpinnerLoading(false);
                setDisabledButton(false);
                return null;
            }
        }

        var imageStartMedio = "";
        if (tabTypeServiceMedio==="valla") {
            imageStartMedio = 'https://statics-gridwel.s3.us-east-2.amazonaws.com/imageScreenPhoto/16FB_screen_photo_image_post_202312051723010004168050005.png';
        }else if (tabTypeServiceMedio==="valla-digital") {
            imageStartMedio = 'https://statics-gridwel.s3.us-east-2.amazonaws.com/img/valla-digital.png';
        }else if (tabTypeServiceMedio==="television") {
            imageStartMedio = 'https://statics-gridwel.s3.us-east-2.amazonaws.com/img/valla-television.jpg';
        }else if (tabTypeServiceMedio==="radio") {
            imageStartMedio = 'https://statics-gridwel.s3.us-east-2.amazonaws.com/img/valla-radio.jpg';
        }else if (tabTypeServiceMedio==="influencer") {
            imageStartMedio = 'https://statics-gridwel.s3.us-east-2.amazonaws.com/img/influencer.png';
        }
        fetch(config.urlApi + '/api/v1/new-screen-valla/', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${cookies.access_token}`
            },
            body: JSON.stringify({ 
              type_business: typeValla,
              name: nameValla,
              photo_tv: imageStartMedio,
              latitud: '',
              longitud: '',
              width_screen_active: widthtValla,
              height_screen_active: heightValla,
              type_service_app: tabTypeServiceMedio,
              poste_height: sizePosteValla,
              number_faces_valla: parseInt(numberFacesValla),
              audiencia: audiencia,
              programa: programa
            })
          })
            .then((response) => response.json())
            .then(data => {
              if (data.status >= 400) {
                showToastError("Ha ocurrido un error al agregar una nueva valla");
              } else {
                navigate('/editar-medio-publicitario/' + data.message);
                // getListScreens();
                // setShowModalValla(false);
              }
              setSpinnerLoading(false);
              setDisabledButton(false);
            })
            .catch(e => {
              showToastError("Inténtalo más tarde.");
              setSpinnerLoading(false);
              setDisabledButton(false);
              return e;
            });

    }

    const [showModalValla, setShowModalValla] = useState(false);

    const handleCloseModalValla = () => setShowModalValla(false);
    const handleShowModalValla = () => {
        setShowModalValla(true);
        setShowFormMedio(false);
    }

    const toggleFullScreen = (elem, status) => {

        if (status) {
          if (elem.requestFullScreen) {
            elem.requestFullScreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      }

    return (
        <>
        <br></br><br></br><br></br>
        <NavBarTop></NavBarTop>

        <div className='contentIndexNavBar' style={{ background: '#fff' }}>
        <div style={{ margin: 'auto', maxWidth: '800px', background: '#fff' }}>
            <div className='tabs-medios'>
                <div>
                    <button onClick={()=>{getListScreens('influencer');setKeyTabShowGetScreens('influencer');setKeyTabTypeServiceMedio('influencer');setCookies('tab_current', 'influencer', { path: '/' });}} className={tabShowGetScreens==='influencer'?'active ':''}>
                        <BsPersonBadge />{' '}
                        Páginas
                    </button>
                </div>
                {/* <div>
                    <button onClick={()=>{getListScreens('valla-digital');setKeyTabShowGetScreens('valla-digital');setKeyTabTypeServiceMedio('valla-digital');setCookies('tab_current', 'valla-digital', { path: '/' });}} className={tabShowGetScreens==='valla-digital'?'active ':''}>
                        <BsCollection />{' '}
                        Vallas Digitales
                    </button>
                </div> */}
                <div>
                    <button onClick={()=>{getListScreens('valla');setKeyTabShowGetScreens('valla');setKeyTabTypeServiceMedio('valla');setCookies('tab_current', 'valla', { path: '/' });}} className={tabShowGetScreens==='valla'?'active ':''}>
                        <BsEasel />{' '}
                        Vallas
                    </button>
                </div>
                {/* <div>
                    <button onClick={()=>{getListScreens('television');setKeyTabShowGetScreens('television');setKeyTabTypeServiceMedio('television');setCookies('tab_current', 'television', { path: '/' });}} className={tabShowGetScreens==='television'?'active ':''}>
                        <BsTv />{' '}
                        Televisión
                    </button>
                </div>
                <div>
                    <button onClick={()=>{getListScreens('radio');setKeyTabShowGetScreens('radio');setKeyTabTypeServiceMedio('radio');setCookies('tab_current', 'radio', { path: '/' });}} className={tabShowGetScreens==='radio'?'active ':''}>
                        <BsDisc />{' '}
                        Radio
                    </button>
                </div> */}
                
            </div>
        </div>
        
            {!loading &&
                <section className="feature-product" style={{ maxWidth: '100%', justifyItems: 'center', justifyContent: 'center', margin: 'auto' }}>
                    <div className="product-all">
  
                        {itemScreen &&
                            <div className="row">
                                {itemScreen.map((item) => <ItemCard key={item.screen.screen_id} item={item.screen} tabShowGetScreens={tabShowGetScreens} handleOpenModalSharing={handleOpenModalSharing} buttonDelete={handleShowModalDeleteScreen} buttonFull={()=>toggleFullScreen(document.body, true)} />)}
                            </div>
                        }
                        
                        
                        {!itemScreen &&
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <BsFillInfoCircleFill color='#838080' size={50} />
                                <h3 style={{ paddingTop: 10, color: '#838080' }}>No tienes registrados sitios para Pautar</h3>
                            </div>
                        }
                    </div>
                </section>
            }
            {loading &&
                <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                    <h1 className="text-login-title" style={{ margin: 'auto' }}>
                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                    </h1>
                </div>
            }
        </div>

       


            {idScreenCurrentSelect &&
                <Modal
                    show={showModalDeleteScreen}
                    dialogClassName="modal-90w"
                    centered
                    onHide={() => setShowModalDeleteScreen(false)}>
                    <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Eliminar Pantalla</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Si eliminas la pantalla no podrás recuperarla.</p>
                        {/* <button className='btn btn-default'>Desactivar</button> */}
                        <button className='btn btn-danger' onClick={DeleteScreen}>Eliminar</button>
                    </Modal.Body>
                </Modal>
            }

            {spinnerLoadingDeleting&&
                <SpinnerLoadingFixed />
            }

          

            <ToastContainer />

            {showsetShowOptionsNewScreen&&
                <div onClick={()=>handleCloseOptionsNewScreen()} style={{ background: '#000', position: 'fixed', width: '100%', height: '100vh', top: 0, left: 0, opacity: 0.8, zIndex: 999 }}></div>
            }

            {!showVincular&&
                <div className='responsiveNavBarFooter sombras-tv-card' style={{ background: '#dc3545', position: 'fixed', right: 30, bottom: 30, border: 'none', borderRadius: 100, width: 70, height: 70, padding: 0, display: 'flex', zIndex: 999 }}>

                    
                {/* {showsetShowOptionsNewScreen?
                    <button onClick={handleCloseOptionsNewScreen} style={{ background: 'transparent', border: 'none', margin: 'auto' }}>
                        <BsXLg size={25} color='#fff' />
                    </button>
                    :
                    <button onClick={handleShowOptionsNewScreen} style={{ background: 'transparent', border: 'none', margin: 'auto' }}>
                        <BsPlus size={40} color='#fff' />
                    </button>
                } */}

                {showModalValla?
                    <button onClick={handleCloseModalValla} style={{ background: 'transparent', border: 'none', margin: 'auto' }}>
                        <BsXLg size={25} color='#fff' /> 
                    </button>
                    :
                    <button onClick={handleShowModalValla} style={{ background: 'transparent', border: 'none', margin: 'auto' }}>
                        <BsPlus size={40} color='#fff' />
                    </button>
                }

                
                {showsetShowOptionsNewScreen&&
                    <div>
                        <div style={{ background: '#fff', position: 'fixed', right: '5%', bottom: '15%', display: 'grid', padding: '20px 30px 20px 30px', borderRadius: 10 }}>
                            <button style={{ border: 'none', background: 'transparent' }} onClick={()=>{handleShowModalValla();handleCloseOptionsNewScreen();}}><FcAddRow /> Valla Publicitaria</button>
                            <br></br>
                            <button style={{ border: 'none', background: 'transparent' }} onClick={()=>{handleShowVincular();handleCloseOptionsNewScreen();}}><FcAddImage /> Pantalla SmartTV</button>
                        </div>
                    </div>
                }

            </div>
            }

            
            
            
            


            <Modal show={showModalValla} onHide={handleCloseModalValla} centered>
                <Modal.Header closeButton style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }}>
                <Modal.Title style={{ width: '100%' }}>Registrar medio de Pautas
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    {!showFormMedio?
                        <div className='buttons-option-modal-medios'>
                            <div>
                                <button onClick={()=>{setKeyTabTypeServiceMedio('influencer');setShowFormMedio(true)}} className={tabTypeServiceMedio==='influencer'?'active ':''}>
                                    <BsPersonBadge />{' '}
                                    Página
                                </button>
                            </div>
                            <div>
                                <button onClick={()=>{setKeyTabTypeServiceMedio('valla-digital');setShowFormMedio(true)}} className={tabTypeServiceMedio==='valla-digital'?'active ':''}>
                                    <BsCollection />{' '}
                                    Vallas Digitales
                                </button>
                            </div>
                            <div>
                                <button onClick={()=>{setKeyTabTypeServiceMedio('valla');setShowFormMedio(true)}} className={tabTypeServiceMedio==='valla'?'active ':''}>
                                    <BsEasel />{' '}
                                    Vallas Físicas
                                </button>
                            </div>
                            {/* <div>
                                <button onClick={()=>{setKeyTabTypeServiceMedio('television');setShowFormMedio(true)}} className={tabTypeServiceMedio==='television'?'active ':''}>
                                    <BsTv />{' '}
                                    Televisión
                                </button>
                            </div>
                            <div>
                                <button onClick={()=>{setKeyTabTypeServiceMedio('radio');setShowFormMedio(true)}} className={tabTypeServiceMedio==='radio'?'active ':''}>
                                    <BsDisc />{' '}
                                    Radio
                                </button>
                            </div> */}
                        </div>
                :
                    <form onSubmit={handleSubmitNewMedioPautas}>
                        <div className="edit-pautty-form" style={{ background: '#f6f6f6', padding: 15, borderRadius: 20, marginBottom: 5 }}>
                            <div className="row">

                                <div style={{ position: 'relative', textAlign: 'center' }}>
                                    <label >Nombre</label>
                                    <input autoComplete="false" className='input-form-pautty' onChange={handleChangeNameValla} maxLength={30} type="text" placeholder='Nombre' name="vallaName" value={nameValla} />
                                    
                                    {(tabTypeServiceMedio==='valla' || tabTypeServiceMedio==='valla-digital')&&
                                        <>
                                        <div className='row'>
                                            <label>Dimensiones (metros)</label>
                                            <div className='col-4' style={{ padding: 10, display: 'flex' }}>
                                                <span style={{ paddingRight: 5 }}>Ancho:</span>
                                                <input className='input-medida-valla' onChange={handleChangeWidthValla} maxLength={4} type="number" placeholder='0' name="widthValla" value={widthtValla} />
                                            </div>
                                            <div className='col-4' style={{ padding: 10 }}>
                                                <span style={{ paddingRight: 5 }}>Alto:</span>
                                                <input className='input-medida-valla' onChange={handleChangeHeightValla} maxLength={4} type="number" placeholder='0' name="heightValla" value={heightValla} />
                                            </div>
                                            <div className='col-4' style={{ padding: 10 }}>
                                                <span style={{ paddingRight: 5 }}>Poste:</span>
                                                <input className='input-medida-valla' onChange={handleChangeSizePosteValla} maxLength={4} type="number" placeholder='0' name="sizePosteValla" value={sizePosteValla} />
                                            </div>
                                        </div>
                                        
                                        <div className='row'>
                                            <div className='col-12 row' style={{ justifyContent: 'center', justifyItems: 'center' }}>
                                                <div className='col-12' style={{ margin: 'auto', border: '2px solid #404040', background: '#fff', width: widthtValla*(window.screen.width/30), height: heightValla*(window.screen.width/50) }}>
                                                </div>
                                                <div className='col-12' style={{ marginTop: -10 }}>
                                                    <img alt='valla' width={widthtValla*(window.screen.width/30)} src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/base-valla-largo.png' />
                                                </div>
                                                <div className='col-12' style={{ marginTop: -10 }}>
                                                    <img alt='valla' width={widthtValla*(window.screen.width/30)} height={sizePosteValla*(window.screen.width/50)} src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/base-valla-poste.png' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-12' style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
                                                <span style={{ paddingRight: 5, paddingTop: 5 }}>Cantidad de caras:</span>
                                                <Form.Group>
                                                    <Form.Select style={{ width: 60 }} onChange={handleChangeNumberFacesValla} value={numberFacesValla}>
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        </>
                                    }

                                    {(tabTypeServiceMedio==='television' || tabTypeServiceMedio==='radio')&&
                                        <div>
                                            <label>Nombre de Programa</label>
                                            <input autoComplete="false" className='input-form-pautty' onChange={handleChangePrograma} maxLength={30} type="text" placeholder='eje: Noticias' name="programaName" value={programa} />
                                            <div style={{ display: 'flex' }}>
                                                <label style={{ paddingTop: 10 }}>Audiencia</label>
                                                <input autoComplete="false" className='input-form-pautty' onChange={handleChangeAudiencia} maxLength={30} type="text" placeholder='100.000 mensuales' name="audienciaQ" value={audiencia} />
                                            </div>
                                        </div>
                                    }

                                </div>

                                

                                {(tabTypeServiceMedio==='valla' || tabTypeServiceMedio==='valla-digital')&&
                                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ textAlign: 'center' }}>
                                        <label >Ubicación</label>
                                        <Form.Group className="mb-3">
                                            <Form.Select className='input-form-pautty' onChange={handleChangeTypeValla} value={typeValla}>
                                                {config.types_screen_location_valla.map((item) => <option key={item.id} value={item.name}>{item.name}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                }

                            </div>
                        </div>
                        
                        <button style={{ float: 'right' }} disabled={disabledButton} className='btn btn-default color-bottons' type='submit' variant="primary">
                            Crear
                            {spinnerLoading &&
                                <div className="d-flex justify-content-center" style={{ float: 'right', paddingTop: 5, paddingRight: 8, marginLeft: 5 }}>
                                    {' '}<span className="spinner-border spinner-border-sm button-sppiner-pauty" role="status" aria-hidden="true"></span>
                                </div>
                            }
                        </button>
                    </form>
                    
                }
                    <button style={{ float: 'right' }} className='btn btn-default' variant="secondary" onClick={handleCloseModalValla}>
                        Cancelar
                    </button>
                </Modal.Body>
            </Modal>



            <Modal
                show={showModalSharing}
                onHide={handleCloseModalSharing}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                    <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Compartir en redes sociales</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ButtonShared id_link={idCodeScreenSharingCurrent} title={titleScreenSharingCurrent} type_shared={'valla'} />
                </Modal.Body>
                </Modal>
            

        </>
    )
}

export default HomePartner;