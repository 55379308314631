import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';

const NavSearch = () => {
    const navigate = useNavigate();

    const [valueInput, setValueInput] = useState();

    const changeValueInput = (event) => {
        setValueInput(event.target.value);
    }

    const formLinkValueInput = (event) => {
        event.preventDefault();
        if (valueInput!==undefined && valueInput!=='null' && valueInput!=='' && valueInput!=='undefined') {
            navigate('/?buscar='+valueInput);
        }
    }
    
    return (
        <div style={{  margin: 'auto', width: '100%', position:'relative' }}>
            <h4>Pauta con Pautty</h4>
            <form onSubmit={formLinkValueInput}>
                <input onChange={changeValueInput} value={valueInput} type="text" className='input-search-home' placeholder='¿Dónde quieres publicar?' />
                <button style={{ border: 'none', background: 'transparent' }} title='¿Dónde quieres publicar?'><i className='button-search-input-home-navbar' type="submit"><BsSearch size={30} color='#fff' /></i></button>
            </form>
            {/* <TagsSearch /> */}
        </div>
    )
}

export default NavSearch;