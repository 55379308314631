import React from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { deleteCookies } from '../deleteCookies';

const ValidLogin = () => {
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();
    if (cookies.access_token===undefined) {
        setCookies('SecondsOTP', 0, { path: '/' });
        deleteCookies();
        // navigate('/login');
        setCookies('pathBefore', window.location.pathname, { path: '/' });
        window.location.href = '/login';
    }
    return (
        <></>
    )
}

export default ValidLogin;