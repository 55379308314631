import React, { Component } from 'react';
import NavBarFooter from '../general/NaBarFooter';
import HeaderBack from '../general/HeaderBack';

export class Tutorials extends Component {

  render() {
    return(
      <>
      <HeaderBack urlBack={''} title={'Tutorial'} needLogin={true} />
      <div className='contentIndexNavBar' style={{ textAlign: 'center', padding: 15, background: "#fff" }}>
        <br></br><br></br><br></br>
        <h4>¿Cómo generar más ingresos en tu negocio?</h4>
        <hr></hr>
        <p>Si tienes en tu negocio un Smart TV con conexión a internet, podrás incrementar los ingresos con el apoyo de Pautty.</p>
        <img src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/tutorial1.png' height={200} alt='Tutorial pautty' />
        <p style={{ paddingTop: 10 }}>Instala Pautty o ingresa a https://valla.pautty.com en tu Smart TV y lee el QR que aparece en pantalla </p>
        <p>Al ingresar al link, deberás darle un nombre para identificar tu pantalla y dar una ubicación de la misma.</p>
        <p>Después de esto, la pantalla quedará activa y tus clientes podrán hacer uso para publicar lo que deseen.</p>
        <p>Tú también tendrás la opción de agregar tus productos y servicios de una forma fácil y rápida.</p>
      </div>
      <NavBarFooter></NavBarFooter>
      </>
    )
  }

}