import React, { useEffect, useRef, useState } from 'react';

import { config } from '../configPautty';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import Tv from '../studio-pautty/Tv';



const Play = () => {
    const [cookies] = useCookies(['user']);
    let { code_checksum } = useParams();

    const intervalId = useRef(null);
    const [count, setCount] = useState(0);

    const [postScreen, setPostScreen] = useState([]);
    const [postScreenCurrent, setPostScreenCurrent] = useState();

    useEffect(() => {
        if (postScreen.length===0) {
            getListPostScreen();
        }
        intervalId.current = setInterval(() => {
            if (count < postScreen.length) {
                setCount((count) => count + 1);
                if (postScreen) {
                    setPostScreenCurrent(postScreen[count]);
                }
            } else {
                setCount((count) => 0);
                setPostScreenCurrent(postScreen[0]);
                getListPostScreen();
            }
            }, 15000);
            return () => {
            clearInterval(intervalId.current);
            }       
    }, [count, postScreen]);

    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const getListPostScreen = () => {
        fetch(config.urlApi + '/api/v1/post-by-screen/?code_checksum='+code_checksum, {
            method: "GET"
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status) {
                    if (data.status===404) {
                        showToastError("No existen Pautas pendientes por mostrar.");
                    }
                }else{
                    setPostScreen(data);
                    setPostScreenCurrent(data[0]);
                }
            })
            .catch(e => {
                return e;
            });
    }

    
    return (
        <>
            
            {postScreenCurrent&&
                <div style={{ cursor: 'none' }}>
                    {/* <span>count:: {count}</span> */}
                   <Tv preview={true} template={postScreenCurrent.post_screen.option_style} elements={postScreenCurrent.element_post} itemPostScreen={postScreenCurrent.post_screen} widthPreview={window.screen.width} heightPreview={window.screen.height} isPlay={true} ></Tv>
                </div>
            }

        <ToastContainer />
        </>
    )
}

export default Play;