import React, { Component } from 'react';
import HeaderBack from '../HeaderBack';
// import { BsSearch } from "react-icons/bs";

export class PQR extends Component {

  render() {

    return (
      <>
        <HeaderBack urlBack={''} title={'Ayuda / PQR'} needLogin={true} />
        <div className="profile-wrapper-content mt-70 centerMaxContentPage" style={{ paddingTop: '70px' }}>

          <div className="container">

            <div className="support-wrapper py-3">
              <div className="card">
                <div className="card-body" style={{ textAlign: 'center' }}>
                  <h4 className="faq-heading text-center">¿Cómo podemos ayudarte?</h4>
                  <p>Envíanos tu petición, queja o reclamo y te contactaremos lo más pronto posible</p>
                  {/* <form className="faq-search-form" action="#" method="">
                    <input className="form-control" type="search" name="search" placeholder="Buscar" />
                    <button type="submit"><BsSearch /></button>
                  </form> */}

                  {/* <a target='_blank' style={{ padding: 8 }} href={'https://api.whatsapp.com/send?phone=573208237758&text=Hola,+quiero+contactarme+con+un+asesor+de+Pautty.com'}>Contactarme con un asesor por medio de Whatsapp</a> */}
                  {/* %0A para salto de linea */}
                  <p>Correo de contacto: <b>info@pautty.com</b></p>
                </div>
              </div>

              <div className="accordian-area-wrapper mt-3">

                {/* <div className="card accordian-card"> */}
                  {/* <div className="card-body"> */}
                    {/* <h5 className="accordian-title">Preguntas frecuentes</h5> */}
                    {/* <br></br>
                    <Accordion defaultActiveKey={['1']} alwaysOpen> */}
                      {/* <Accordion.Item eventKey="0">
                        <Accordion.Header>Tiempo de revisión de publicaciones</Accordion.Header>
                        <Accordion.Body>
                          El equipo de Pautty tiene hasta 24 horas para revisar tu publicación. Si no se realiza la aprobación en este periodo de tiempo, se da por entendido que la publicación no cumple con los términos de Pautty.
                        </Accordion.Body>
                      </Accordion.Item> */}
                      {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>¿Por qué mi publicación sigue en revisión?</Accordion.Header>
                        <Accordion.Body>
                          Después de pasadas las 24 horas de la creación de la publicación y no ha sido aprobada por el equipo de Pautty, es probable que no haya cumplido con los <Link style={{ textDecorationLine: 'blink' }} to="/terms">Términos y condiciones</Link>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>¿Cuándo recibiré el pago de las ganancias de mi pantalla?</Accordion.Header>
                        <Accordion.Body>
                          El desembolso se realizará en los últimos días de cada mes, cuando nos aseguremos que se haya cumplido con nuestos <Link style={{ textDecorationLine: 'blink' }} to="/terms">Términos y condiciones</Link>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>¿Cuántas veces saldrá mi anuncio durante el día?</Accordion.Header>
                        <Accordion.Body>
                          La cantidad de veces que saldrá el anuncio, está sugeto al horario del local o sitio donde se encuentre la pantalla registrada en Pautty, el incumplimiento por parte del dueño, o arrendador de la pantalla, Pautty se verá obligado a suspender o cancelar la cuenta y se reagendará o negociará con el usuario para que se visualice en otra pantalla ofrecida por Pautty. 
                        </Accordion.Body>
                      </Accordion.Item> */}
                    {/* </Accordion> */}

                  {/* </div> */}
                {/* </div> */}
              </div>

            </div>
          </div>

        </div>
      </>
    )
  }

}