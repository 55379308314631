import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../configPautty';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import { FaArrowLeft } from 'react-icons/fa';
import Tv from '../studio-pautty/Tv';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Modal } from 'react-bootstrap';
import { BsFacebook, BsLinkedin, BsShare, BsTwitter, BsWhatsapp } from 'react-icons/bs';
import ButtonShared from '../utils/ButtonShared';


const MiValla = () => {
  const [cookies] = useCookies(['user']);
  // es dispositivo movil
  // const [isDispositivoMovil] = useState(/Mobi/.test(navigator.userAgent));
    let { post } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      getPautasUserMiValla();
    }, [post]);

    const [showModalSharing, setShowModalSharing] = useState(false);
    const [idPautaSharingCurrent, setIdPautaSharingCurrent] = useState();
    const [titlePautaSharingCurrent, setTitlePautaSharingCurrent] = useState("");

    const handleOpenModalSharing = (id, title_pauta) => {
      setIdPautaSharingCurrent(id);
      setTitlePautaSharingCurrent(title_pauta);
      setShowModalSharing(true)
    };

    const handleCloseModalSharing = () => setShowModalSharing(false);

    const showToastError = (error) => {
      toast.error(error, {
        position: "bottom-center",
        autoClose: 5000,
        rtl: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    }


    const [pautasByUser, setGetPautasByUser] = useState();

    const getPautasUserMiValla = () => {
        fetch(config.urlApi + '/api/v1/user/pautas/' , {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            }
        })
            .then((response) => response.json())
            .then(data => {
                if (data){
                    if (data.status>404) {
                      showToastError("Ha ocurrido un error.");
                    }else{
                      setGetPautasByUser(data[0]);
                    }
                }
            })
            .catch(e => {
                return e;
            });
    }

    const newPost = () => {
      setLoading(true);
      fetch(config.urlApi + '/api/v1/post-screen/', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${cookies.access_token}`
        },
        body: JSON.stringify({
            name: 'The Burguer',
            type_post: 'usuario',
            screen_id: config.idScreenUser,
            originator_post: 'L',
            option_style: 1,
            description: 'Super Delicious Food' 
        })
    })
        .then((response) => response.json())
        .then(pauta => {
            if (!pauta.status) {
              navigate('/editar-pantalla/' + config.idScreenUser + '/editar-contenido/' + pauta)
            } else {
                showToastError(pauta.message);
            }
            setLoading(false);
        })
        .catch(e => {
            showToastError("Inténtalo más tarde. ");
            setLoading(false);
            return e;
        });
    }


  
   
    return (
        <div style={{ background: '#C8C7C7', minHeight: '100vh', justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>          
          {pautasByUser ?
            <div style={{ margin: 'auto', position: 'relative' }} >
              <Tv template={pautasByUser.option_style} itemPostScreen={pautasByUser} widthPreview={pautasByUser.width_screen_active} heightPreview={pautasByUser.height_screen_active} ></Tv>
              <div className='button-editar-mi-valla'>
                <button style={{ color: '#292929', background: '#F7CF57' }} onClick={()=>{
                  navigate('/editar-pantalla/' + config.idScreenUser + '/editar-contenido/' + pautasByUser.post_screen_id)
                }} className='btn btn-default button-top-editor'>Editar</button>
              </div>
            </div>
            :
            <div className='content-new-pauta'>
              <div className='row'>
                <h1 className='col-12'>Pauta aquí</h1>
                <div className='col-2'></div>
                <img className='col-8' alt='Nueva pauta' src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/base-valla.png' />
                <div className='col-2'></div>
                <div>
                  {loading?
                    <SpinnerLoadingFixed />
                    :
                    <button className='color-bottons-start' onClick={newPost}>Crear Pauta</button>
                  }                  
                </div>
              </div>
            </div>
          }

          {pautasByUser&&
            <button onClick={()=>handleOpenModalSharing(pautasByUser.post_screen_id, pautasByUser.name)} style={{ color: '#292929', background: '#eaeaea', position: 'absolute', right: 10, top: 60 }} className='btn btn-default button-top-editor'> Compartir {' '} <BsShare color='#292929' size={25} /> </button>
          }
        

          <button onClick={()=>{window.history.back();}} className="button-back-fixed">
            <FaArrowLeft color='#292929' size={30} /> 
          </button>

          <Modal
            show={showModalSharing}
            onHide={handleCloseModalSharing}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Compartir en redes sociales</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ButtonShared id_link={idPautaSharingCurrent} title={titlePautaSharingCurrent} type_shared={'pauta'} />
            </Modal.Body>
            </Modal>

          <ToastContainer />
        </div>
    )
}

export default MiValla;