export const config = {
    urlApi: 'https://api.pautty.com',
//    urlApi: 'http://192.168.18.246:9100',
    // urlApi: 'http://192.168.148.77:9100',
    // urlApi: 'http://192.168.18.246:9100',
    // idScreenUser:196, // dev
    idScreenUser:255, // prod
    welcome: {
        en: "Welcome",
        fa: "خوش آمدید"
    },
    types_screen_location: [
        {"id":"0","name":"Ninguno"},
        {"id":"1","name":"Restaurante"},
        {"id":"2","name":"Bar"},
        {"id":"3","name":"CafeBar"},
        {"id":"4","name":"Comidas Rápidas"},
        {"id":"5","name":"Heladería"},
        {"id":"6","name":"Centro Comercial"},
        {"id":"7","name":"Panadería"},
        {"id":"8","name":"Cafetería"},
        {"id":"9","name":"Hospital"},
        {"id":"10","name":"Hotel"},
        {"id":"12","name":"Tienda de Ropa"},
        {"id":"15","name":"Peluquería"},
        {"id":"16","name":"Gimnasio"},
        {"id":"17","name":"Supermercado"}
    ],
    types_screen_location_valla: [
        {"id":"0","name":"Ninguno"},
        {"id":"1","name":"Vía Pública"},
        {"id":"2","name":"Terraza de edificio"},
        {"id":"3","name":"Paraderos de Autobus"},
        {"id":"4","name":"Montaña"},
        {"id":"6","name":"Centro Comercial"},
        {"id":"7","name":"Panadería"},
        {"id":"8","name":"Cafetería"},
        {"id":"9","name":"Hospital"},
        {"id":"10","name":"Hotel"},
        {"id":"12","name":"Tienda de Ropa"},
        {"id":"15","name":"Peluquería"},
        {"id":"16","name":"Gimnasio"},
        {"id":"17","name":"Supermercado"},
        {"id":"18","name":"Heladería"},
        {"id":"19","name":"Restaurante"},
        {"id":"20","name":"Bar"},
        {"id":"21","name":"CafeBar"},
        {"id":"22","name":"Comidas Rápidas"}
    ],
    types_city_location: [
        {"id":"0","name":"Bogotá", "longitud": "-74.12645417974865", "latitud": "4.642202298461683"},
        {"id":"1","name":"Medellín", "longitud": "-75.577768", "latitud": "6.253467"},
        {"id":"2","name":"Cartagena", "longitud": "-75.498328", "latitud": "10.399314"},
        {"id":"3","name":"Santa Marta", "longitud": "-74.191840", "latitud": "11.240783"},
        {"id":"4","name":"Bucaramanga", "longitud": "-73.118319", "latitud": "7.122544"},
        {"id":"5","name":"Barranquilla", "longitud": "-74.801411", "latitud": "10.981880"},
        {"id":"6","name":"Tunja", "longitud": "-73.355212", "latitud": "5.547836"},
        {"id":"7","name":"Sogamoso", "longitud": "-72.931426", "latitud": "5.721908"},
        {"id":"8","name":"Cali", "longitud": "-76.516639", "latitud": "3.445049"},
        {"id":"9","name":"Yopal", "longitud": "-72.392755", "latitud": "5.335057"}
    ],
    font_family_edit: [
        {"name":"Arial"},
        {"name":"Times New Roman"},
        {"name":"Helvetica"},
        {"name":"Fantasy"},
        {"name":"Serif"},
        {"name":"Monospace"},
        {"name":"Times"},
        {"name":"Courier New"},
        {"name":"Verdana"},
        {"name":"Courier"},
        {"name":"Arial Narrow"},
        {"name":"Candara"},
        {"name":"Ginebra"},
        {"name":"Calibri"},
        {"name":"Optima"},
        {"name":"Cambria"},
        {"name":"Garamond"},
        {"name":"Perpetua"},
        {"name":"Monaco"},
        {"name":"Didot"},
        {"name":"Brush Script MT"},
        {"name":"Lucida Bright"},
        {"name":"Copperplate"},
        {"name":"Comic Sans"},
        {"name":"Alegreya Sans SC"},
        {"name":"Alfa Slab One"},
        {"name":"Allura"},
        {"name":"Anton"},
        {"name":"Antonio"},
        {"name":"Archivo Black"},
        {"name":"Black Ops One"},
        {"name":"Briem Hand"},
        {"name":"Bungee"},
        {"name":"Bungee Inline"},
        {"name":"Bungee Shade"},
        {"name":"Bungee Spice"},
        {"name":"Carter One"},
        {"name":"Changa One"},
        {"name":"Cookie"},
        {"name":"Creepster"},
        {"name":"Freeman"},
        {"name":"Fugaz One"},
        {"name":"Gloria Hallelujah"},
        {"name":"Gochi Hand"},
        {"name":"Herr Von Muellerhoff"},
        {"name":"Holtwood One SC"},
        {"name":"Honk"},
        {"name":"Indie Flower"},
        {"name":"Jacquard"},
        {"name":"Jaro"},
        {"name":"Kalam"},
        {"name":"Lilita One"},
        {"name":"Luckiest Guy"},
        {"name":"Merienda"},
        {"name":"Micro 5"},
        {"name":"Micro 5 Charted"},
        {"name":"Monoton"},
        {"name":"Ms Madi"},
        {"name":"Nanum Brush Script"},
        {"name":"Neuton"},
        {"name":"Nothing You Could Do"},
        {"name":"Oleo Script"},
        {"name":"Pacifico"},
        {"name":"Patua One"},
        {"name":"Paytone One"},
        {"name":"Permanent Marker"},
        {"name":"Pinyon Script"},
        {"name":"Playfair Display SC"},
        {"name":"Protest Guerrilla"},
        {"name":"Protest Revolution"},
        {"name":"Rakkas"},
        {"name":"Rammetto One"},
        {"name":"Rampart One"},
        {"name":"Righteous"},
        {"name":"Rock Salt"},
        {"name":"Rowdies"},
        {"name":"Rubik Broken Fax"},
        {"name":"Rubik Bubbles"},
        {"name":"Rubik Glitch Pop"},
        {"name":"Rubik Lines"},
        {"name":"Rubik Maps"},
        {"name":"Rubik Mono One"},
        {"name":"Rubik Moonrocks"},
        {"name":"Rubik Scribble"},
        {"name":"Russo One"},
        {"name":"Rye"},
        {"name":"Sacramento"},
        {"name":"Sedgwick Ave Display"},
        {"name":"Sen"},
        {"name":"Shadows Into Light"},
        {"name":"Silkscreen"},
        {"name":"Sixtyfour"},
        {"name":"Special Elite"},
        {"name":"Sriracha"},
        {"name":"Tac One"},
        {"name":"Tangerine"},
        {"name":"Titan One"},
        {"name":"Ultra"},
        {"name":"Viga"},
        {"name":"Wallpoet"},
        {"name":"Yeseva One"},
        {"name":"Zen Dotsdisplay"},
    ],
    url_social: [
        {"id":"0","name":"facebook", "pc":"https://facebook.com/", "mobile": "fb://facewebmodal/f?href=https://www.facebook.com/"},
        {"id":"1","name":"whatsapp", "pc":"https://wa.me/57", "mobile": "https://wa.me/57"},
        {"id":"2","name":"instagram", "pc":"https://instagram.com/", "mobile": "instagram://user?username="},
        {"id":"3","name":"twitter", "pc":"https://twitter.com/", "mobile": "twitter://user?screen_name="},
        {"id":"4","name":"tiktok", "pc":"https://tiktok.com/@", "mobile": "https://tiktok.com/@"},
        {"id":"5","name":"youtube", "pc":"https://youtube.com/", "mobile": "vnd.youtube://www.youtube.com/"},
        {"id":"6","name":"airbnb", "pc":"https://airbnb.com/users/show/", "mobile": "https://airbnb.com/users/show/"},
        {"id":"7","name":"pinterest", "pc":"https://pinterest.com/", "mobile": "https://pinterest.com/"},
        {"id":"8","name":"snapchat", "pc":"https://snapchat.com/add/", "mobile": "https://snapchat.com/add/"},
    ],
    images_slider_home: [
        {
            id: 0,
            name: "Pauta con Pautty",
            description: "Pautar nunca fue tan fácil",
            textButton: "Mi Valla",
            image: "https://statics-gridwel.s3.us-east-2.amazonaws.com/img/banner2.png",
            url: "/mi-valla"
        },
        // {
        //     id: 1,
        //     name: "¿Cómo usar Pautty en mi negocio?",
        //     image: "https://statics-gridwel.s3.us-east-2.amazonaws.com/img/banner2.jpg",
        //     url: "/tutoriales/como-empezar-a-usar-pautty"
        // }
    ],
    types_screen_dimensions: [
        {"id":"0","name":"Smart TV", "value": "tv"},
        {"id":"1","name":"Valla Publicitaria", "value": "valla"}
    ],
    templates_vallas_paute_aqui: [
        {"id":"0", "url_image": "https://statics-gridwel.s3.us-east-2.amazonaws.com/img/banner2.jpg"}
    ],
    list_type_time_price: [
        {"id":"0","name":"Por Pauta"},
        {"id":"1","name":"Mensual"},
        {"id":"2","name":"Semanal"},
        {"id":"3","name":"Anual"},
        {"id":"4","name":"Por 15 Días"},
        {"id":"5","name":"Por Día"},
        {"id":"","name":""}
    ],
    list_dias_transmision: [
        {"id":"0","name":""},
        {"id":"1","name":"De Lunes a Viernes"},
        {"id":"2","name":"Fines de Semana"},
        {"id":"3","name":"Festivos"},
        {"id":"4","name":"Todos los Lunes"},
        {"id":"5","name":"Todos los Martes"},
        {"id":"6","name":"Todos los Miércoles"},
        {"id":"7","name":"Todos los Jueves"},
        {"id":"8","name":"Todos los Viernes"},
        {"id":"9","name":"Todos los Sábados"},
        {"id":"10","name":"Todos los domingos"},
        {"id":"11","name":"De Lunes a Domingo"},
        {"id":"12","name":"Una vez al mes"}
    ],
    list_price_options: [
        {"id":"0","name":"Menos de $500.000"},
        {"id":"1","name":"$500.001 a $1'000.000"},
        {"id":"2","name":"$1'000.001 a $3'000.000"},
        {"id":"3","name":"$3'000.001 a $5'000.000"},
        {"id":"4","name":"$5'000.001 a $8'000.000"},
        {"id":"5","name":"$8'000.001 a $15'000.000"},
        {"id":"6","name":"Más de $15'000.000"}
    ],
    list_status_pauta: [
        {"id":"0","name":"Registrado"},
        {"id":"1","name":"Recibido"},
        {"id":"2","name":"Pago Confirmado"},
        {"id":"3","name":"Aprobación de Diseño"},
        {"id":"4","name":"En proceso para publicar"},
        {"id":"5","name":"Publicado"},
        {"id":"6","name":"Finalizado"}
    ],
    // list_links_social_media: [
    //     {"id":"0","name":"Facebook", "url":"https://www.facebook.com/", "nameIcon":"<BsFacebook/>"},
    //     {"id":"1","name":"Twitter", "url":"https://www.twitter.com/", "nameIcon":"<BsTwitter/>"},
    //     {"id":"2","name":"Instagram", "url":"https://www.instagram.com/", "nameIcon":"<BsInstagram/>"},
    //     {"id":"3","name":"Tiktok", "url":"https://www.tiktok.com/", "nameIcon":"<FaTiktok/>"},
    //     {"id":"4","name":"Youtube", "url":"https://www.youtube.com/channel/", "nameIcon":"<BsYoutube/>"},
    //     {"id":"5","name":"Whatsapp", "url":"https://www.whatsapp.com/", "nameIcon":"<BsWhatsapp/>"},
    //     {"id":"6","name":"Linkedin", "url":"https://www.linkedin.com/", "nameIcon":"<BsLinkedin/>"},
    //     {"id":"7","name":"Telegram", "url":"https://www.telegram.com/", "nameIcon":"<BsTelegram/>"},
    //     {"id":"8","name":"Pinterest", "url":"https://www.pinterest.com/", "nameIcon":"<BsPinterest/>"},
    //     {"id":"9","name":"Line", "url":"https://www.line.com/", "nameIcon":"<BsLine/>"},
    //     {"id":"10","name":"Snapchat", "url":"https://www.snapchat.com/", "nameIcon":"<BsSnapchat/>"}
    // ],
    list_city_location_colombia: [
        {"id":"0","name":"Bogotá, Bogotá D.C."},
        {"id":"1","name":"Medellín, Antioquia"},
        {"id":"2","name":"Tunja, Boyacá"},
        {"id":"3","name":"Sogamoso, Boyacá"},
        {"id":"4","name":"Duitama, Boyacá"},
        {"id":"5","name":"Paipa, Boyacá"},
        {"id":"6","name":"Villa de Leyva, Boyacá"},
        {"id":"7","name":"Sáchica, Boyacá"},
        {"id":"8","name":"Sutamarchán, Boyacá"},
    ],
    list_code_plan_payments_pautty: [
        {"id":"0","name":"Versión Premium","value":"25000", "description":"", "type":"premium", "timer":false},
        {"id":"1","name":"Pago x20 IA", "value":"11000", "description":"Pago por 20 descripciones y 20 imágenes generadas con IA", "type":"ia", "timer":false},
        {"id":"2","name":"Pago x40 IA","value":"20000", "description":"Pago por 40 descripciones y 40 imágenes generadas con IA", "type":"ia", "timer":false},
        {"id":"3","name":"Por Día", "value":"", "description":"Pago por un día de Pauta", "type":"pauta", "timer":true},
        {"id":"4","name":"Semanal", "value":"", "description":"Pago por Pauta durante una semana", "type":"pauta", "timer":true},
        {"id":"5","name":"Por 15 Días", "value":"", "description":"Pago por Pauta durante 15 días", "type":"pauta", "timer":true},
        {"id":"6","name":"Mensual", "value":"", "description":"Pago por Pauta durante un Mes", "type":"pauta", "timer":true},
        {"id":"7","name":"Anual", "value":"", "description":"Pago por Pauta durante un año", "type":"pauta", "timer":true},   
        {"id":"8","name":"Por Pauta", "value":"", "description":"Pago por una Pauta", "type":"pauta", "timer":false},
        {"id":"9","name":"Por Campaña", "value":"", "description":"Pago por campaña", "type":"pauta", "timer":false},
        {"id":"10","name":"Por Hora", "value":"", "description":"Pago por Pauta durante una Hora", "type":"pauta", "timer":true},   
    ],
    list_size_text_editar_pauta: [
        {"id":"5","name":"Muy Pequeño","value": "60"},
        {"id":"6","name":"Pequeño","value": "40"},
        {"id":"7","name":"Mediano","value": "20"},
        {"id":"8","name":"Grande","value": "10"},
        {"id":"9","name":"Extra Grande","value": "5"}
    ],
    list_size_links_social_pauta: [
        {"id":"5","name":"Muy Pequeño","value": "60"},
        {"id":"6","name":"Pequeño","value": "40"},
        {"id":"7","name":"Mediano","value": "20"},
        {"id":"8","name":"Grande","value": "10"},
        {"id":"9","name":"Extra Grande","value": "5"}
    ],
    list_links_social_media: [
        {"id":"0","name":"Facebook", "urlWeb":"https://www.facebook.com/","url":"fb://facewebmodal/f?href=", "nameIcon":"<BsFacebook/>"},
        {"id":"1","name":"Twitter", "urlWeb":"https://twitter.com/","url":"twitter://user?screen_name=", "nameIcon":"<BsTwitter/>"},
        {"id":"2","name":"Instagram", "urlWeb":"https://instagram.com/","url":"instagram://user?username=", "nameIcon":"<BsInstagram/>"},
        {"id":"3","name":"Tiktok", "urlWeb":"https://tiktok.com/","url":"https://www.tiktok.com/@", "nameIcon":"<FaTiktok/>"},
        {"id":"4","name":"Youtube", "urlWeb":"https://www.youtube.com/","url":"https://www.youtube.com/", "nameIcon":"<BsYoutube/>"},
        {"id":"5","name":"Whatsapp", "urlWeb":"https://wa.me/","url":"https://wa.me/", "nameIcon":"<BsWhatsapp/>"},
        {"id":"6","name":"Linkedin", "urlWeb":"https://www.linkedin.com/","url":"https://www.linkedin.com/in/", "nameIcon":"<BsLinkedin/>"},
        {"id":"7","name":"Telegram", "urlWeb":"https://t.me/","url":"https://t.me/", "nameIcon":"<BsTelegram/>"},
        {"id":"8","name":"Pinterest", "urlWeb":"https://pinterest.com/","url":"https://pinterest.com/", "nameIcon":"<BsPinterest/>"},
        {"id":"9","name":"Line", "urlWeb":"https://lin.ee/","url":"https://lin.ee/", "nameIcon":"<BsLine/>"},
        {"id":"10","name":"Snapchat", "urlWeb":"https://www.snapchat.com/","url":"https://www.snapchat.com/add/", "nameIcon":"<BsSnapchat/>"},
        {"id":"11","name":"Web", "urlWeb":"https://","url":"https://", "nameIcon":"<WebSite/>"}
    ],
    list_options_templates_tabs: [
        {"id":1,"name":"Plantilla Pautty 1", "category": "marca", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t1.png?" },
        {"id":2,"name":"Plantilla Pautty 2", "category": "marca", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t2.png?" },
        {"id":3,"name":"Plantilla Pautty 3", "category": "marca", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t3.png?" },
        {"id":4,"name":"Plantilla Pautty 4", "category": "marca", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t4.png?" },
        {"id":5,"name":"Plantilla Pautty 5", "category": "marca", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t5.png?" },
        {"id":6,"name":"Plantilla Pautty 6", "category": "marca", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t5.png?" },
        // {"id":7,"name":"Plantilla Pautty 7", "category": "happybirthday", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t5.png?" },
        // {"id":8,"name":"Plantilla Pautty 8", "category": "happybirthday", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t5.png?" },
        // {"id":9,"name":"Plantilla Pautty 9", "category": "love", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t5.png?" },
        // {"id":10,"name":"Plantilla Pautty 10", "category": "love", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t5.png?" },
        // {"id":11,"name":"Plantilla Pautty 11", "category": "baby", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t5.png?" },
        // {"id":12,"name":"Plantilla Pautty 12", "category": "envents", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/img/templates/editor/t5.png?" },
        {"id":100,"name":"Plantilla Pautty 100", "category": "video", "url_content":"https://statics-gridwel.s3.us-east-2.amazonaws.com/videoPost/1_user_video_post_202308181604266487346050005.mp4" },
    ],
}
  