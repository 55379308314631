import React, { Component } from 'react';
import HeaderBack from '../HeaderBack';

export class About extends Component {

  render() {
    return(
      <>
        <HeaderBack urlBack={''} title={'Acerca de Pautty'} needLogin={false}/>
        <p>Acerca de Pautty</p>
      </>
    )
  }

}