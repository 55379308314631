import React, { useEffect, useRef, useState } from 'react';

import { config } from '../../configPautty';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { deleteCookies } from '../../deleteCookies';
import mapboxgl from 'mapbox-gl';
import { ToastContainer, toast } from 'react-toastify';
import ShowTelevision from './Television';
import ShowRadio from './Radio';
import ShowInfluencer from './Influencer';
import { BsArrowLeftShort, BsCollectionPlayFill, BsFillPlayCircleFill, BsFillTelephoneFill, BsShare, BsWhatsapp } from 'react-icons/bs';
import { Modal, Spinner } from 'react-bootstrap';
import Valla from './Valla';
import ButtonShared from '../../utils/ButtonShared';
let dollarIndianLocale = Intl.NumberFormat('es-CO');

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX;

const Show = () => {
    const mapMiValla = useRef(null);
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [spinnerLoadingButtonPautar, setSpinnerLoadinguttonPautar] = useState(false);
    const [cookies, setCookies] = useCookies(['user']);

    const [heigthMap, setHeigthMap] = useState(300);

    let { code_checksum } = useParams();
    const navigate = useNavigate();
    const [valla, setValla] = useState();
    const [markerLocationValla] = useState(new mapboxgl.Marker({ "color": "#f52d3f" }));
    const [objectPautaNew, setObjectPautaNew] = useState({
        name: 'The Burguer',
        type_post: 'valla',
        originator_post: 'L',
        option_style: 1,
        description: 'Super Delicious Food'
    });
    
    useEffect(() => {
        getScreensById();     
        return () => null;
    }, []);

    const toggleFullScreen = (elem, status) => {

        if (status) {
          if (elem.requestFullScreen) {
            elem.requestFullScreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      }

    const getScreensById = () => {
        setSpinnerLoading(true);
        
        fetch(config.urlApi + '/api/v1/screen/' + code_checksum, {
            method: "GET"
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status === 404) {
                    showToastError(data.message);
                    navigate('/not-found/404');
                } else {
                    if (data.status === 401) {
                        deleteCookies();
                        setCookies('pathBefore', window.location.pathname, { path: '/' });
                        navigate('/login');
                    }
                    if (!data.status) {
                        setValla(data.screen);
                        console.log("data.screen");
                        if (data.screen.type_service_app==='influencer') {
                            setHeigthMap(0);
                        }
                        setObjectPautaNew(objectPautaNew => ({
                            ...objectPautaNew,
                            screen_id: parseInt(data.screen.screen_id)
                          }));
                        // getListPostScreen('',false);
                        
                        if (data.screen.type_service_app === 'valla' || data.screen.type_service_app === 'valla-digital') {
                            var nav = new mapboxgl.NavigationControl({ visualizePitch: true });
                            mapMiValla.current = new mapboxgl.Map({
                                container: 'mapMiValla',
                                style: 'mapbox://styles/mapbox/streets-v11',
                                center: [-74.15645417974865, 4.642202298461683],
                                zoom: 10,
                    
                            }).addControl(nav, 'bottom-right');
                            markerLocationValla.setLngLat([data.screen.longitud, data.screen.latitud]).setPopup(
                                new mapboxgl.Popup({ offset: 25 }).setHTML(
                                    `<div><h4 style="color:#000000">` + data.screen.name + `</h4><p>` + data.screen.type_business + ` </p></div>`
                                )
                            ).addTo(mapMiValla.current);
                            mapMiValla.current.jumpTo({
                                center: [data.screen.longitud, data.screen.latitud],
                            });
                        }
                        // AddVisitToMedio(data.screen.screen_id, data.screen.number_visits);
                        // GetUserPremiumMessage();
                    } else {
                        showToastError(data.message);
                    }
                }
                setSpinnerLoading(false);
            })
            .catch(e => {
                setSpinnerLoading(false);
                // showToastError("Inténtalo más tarde. " + e);
            });
    }

    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const AddVisitToMedio = (id_creen, visits) => {
        fetch(config.urlApi + '/api/v1/add-view-screen/', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            },
            body: JSON.stringify({
                number_visits: parseInt(visits)+1,
                screen_id: parseInt(id_creen),   
            })
        })
            .then((response) => response.json())
            .then(data => {
                if (!data.status) {
                    visits = parseInt(visits)+1;
                }
            })
            .catch(e => {
                return e;
            });
    }

    const handleSubmitAddInitPostScreen = (pauta) => {
        pauta.screen_id=parseInt(valla.screen_id);
        pauta.type_post=valla.type_service_app;
        setSpinnerLoadinguttonPautar(true);
        fetch(config.urlApi + '/api/v1/post-screen/', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            },
            body: JSON.stringify(pauta)
        })
            .then((response) => response.json())
            .then(data => {
                if (!data.status) {
                    navigate('/editar-pantalla/' + valla.screen_id + '/editar-contenido/' + data);
                } else {
                    if (data.status===401) {
                        deleteCookies();
                        setCookies('pathBefore', window.location.pathname, { path: '/' });
                        navigate('/login');
                    }
                    showToastError(data.message);
                }
                setSpinnerLoadinguttonPautar(false);
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                setSpinnerLoadinguttonPautar(false);
                return e;
            });
    }

    const [showModalSharing, setShowModalSharing] = useState(false);
    

    const handleOpenModalSharing = () => {
        setShowModalSharing(true)
    };
    const handleCloseModalSharing = () => setShowModalSharing(false);

    
    return (
        <div style={{ background: '#f4f4f4', height: '100%' }}>
                <button className="top-to-breadcrumb-show" onClick={() => {
                        navigate('/');
                    }}>
                    <BsArrowLeftShort size={25} />
                </button>

            {!spinnerLoading &&
                <div>

                    {(valla.type_service_app === 'valla-digital' || valla.type_service_app === 'valla')&&
                        <Valla valla={valla} />
                    }

                    {/* {valla.type_service_app === 'television'&&
                        <ShowTelevision valla={valla} />
                    }

                    {valla.type_service_app === 'radio'&&
                        <ShowRadio valla={valla} />
                    } */}

                    {valla.type_service_app === 'influencer'&&
                        <ShowInfluencer valla={valla} />
                    }

                </div>
            }
            {valla&&
                <div>
                    
                        <div className='content-static-options-show'>
                                <div className='component-static-options' >
                    
                                <button style={{ background: '#F7CF57', color: '#292929' }} onClick={()=>{toggleFullScreen(document.body, true);navigate('/play/'+valla.code_checksum);}}><BsFillPlayCircleFill size={25} /></button>

                                    
                                    {spinnerLoadingButtonPautar?
                                        <div>
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                    :
                                    <>
                                    {(valla.time_price !== '' && valla.time_price !== undefined && valla.price !== "" && valla.price !== '0' && valla.available && valla.valid_screen) ?
                                    <button style={{ background: '#dc3545', color: '#ffffff' }} onClick={()=>{handleSubmitAddInitPostScreen(objectPautaNew);}}>
                                        Pautar
                                    </button>
                                    :
                                    <div >
                                    {valla.number_whatsapp&&
                                        <button style={{ background: '#25d366', color: '#ffffff' }} onClick={()=>{
                                            window.open(`https://wa.me/${valla.number_whatsapp}?text=${encodeURI("Hola, te encontré en Pautty y quisiera pautar en tu sitio")}`);
                                            }} >
                                                <BsWhatsapp size={25} />
                                        </button>
                                    }
                                    {valla.phone_number&&
                                        <button style={{ background: '#19a7a4', color: '#fff' }} onClick={()=>{
                                            window.open(`tel:${valla.phone_number}`);
                                            }}>
                                                <BsFillTelephoneFill size={25} />
                                        </button>
                                    }
                                    </div>
                                    }
                                    
                                    </>
                                    
                                    }
                                    

                                <button style={{ color: '#292929' }} onClick={handleOpenModalSharing}><BsShare size={25} /> </button>

                                </div>

                            </div>

                </div>
                
            }

            {valla&&
                <Modal
                    show={showModalSharing}
                    onHide={handleCloseModalSharing}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                    <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Compartir en redes sociales</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ButtonShared id_link={valla.code_checksum} title={valla.name} type_shared={'valla'} />
                    </Modal.Body>
                    </Modal>
            }


                    <div id="mapMiValla" style={{ width: "100%", height: `${heigthMap}px`, marginBottom: "95px", cursor: "pointer" }}></div>
        <ToastContainer />

        {/* <div className='text-center-claro'><span>Por Pautty.com</span></div> */}

        </div>
    )
}

export default Show;