import React, { useEffect, useState } from 'react';
import { config } from '../../configPautty';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { BsFillPatchCheckFill, BsFacebook, BsInstagram, BsYoutube, BsFillPersonFill, BsWhatsapp, BsLinkedin, BsTelegram, BsPinterest, BsLine, BsSnapchat, BsTwitterX, BsLink } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
let dollarIndianLocale = Intl.NumberFormat('es-CO');

const ShowInfluencer = ({ valla }) => {

    const [cookies] = useCookies(['user']);

    const [showModalImage, setShowwModalImage] = useState(false);

    const handlewModalImageClose = () => setShowwModalImage(false);
    const handlewModalImageShow = () => setShowwModalImage(true);

    const converterToFollowers = (followers) => {
        if (parseInt(followers) < 1000) {
            return followers;
        } else if (parseInt(followers) >= 1000 && parseInt(followers) < 1000000) {
            return followers.substring(0, followers.length - 3) + " K";
        } else if (parseInt(followers) >= 1000000) {
            return followers.substring(0, followers.length - 6) + " mill.";
        }
    }

    const [isDispositivoMovil] = useState(/Mobi/.test(navigator.userAgent));

    useEffect(() => {
        getElementScreen(valla.screen_id);
        return () => null;
    }, []);

    const [listElementsScreen, setListElementsScreen] = useState([]);

    const getElementScreen = (idScreen) => {
        fetch(config.urlApi + '/api/v1/element-screen/'+idScreen, {
            method: 'GET'
        })
            .then((response) => response.json())
            .then(data => {
                if (data) {
                    setListElementsScreen(data);
                }
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    
    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const GetIconSocialMedia = ({nameSocial}) => {
        
        var urlFinish = getUrlSocialMedia(nameSocial.url, nameSocial.name);
        if (nameSocial.description==="Facebook") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsFacebook color='#3b5998' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }
        if (nameSocial.description==="Twitter") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsTwitterX color='#000000' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }
        if (nameSocial.description==="Instagram") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsInstagram color='#3f729b' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }
        if (nameSocial.description==="Tiktok") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><FaTiktok color='#000000' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }
        if (nameSocial.description==="Youtube") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsYoutube color='#c4302b' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }
        if (nameSocial.description==="Whatsapp") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsWhatsapp color='#25d366' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }
        if (nameSocial.description==="Linkedin") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsLinkedin color='#0e76a8' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }    
        if (nameSocial.description==="Telegram") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsTelegram color='#0088cc' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }  
        if (nameSocial.description==="Pinterest") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsPinterest color='#C8232C' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        } 
        if (nameSocial.description==="Line") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsLine color='#00b900' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }    
        if (nameSocial.description==="Snapchat") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsSnapchat color='#FFFC00' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }
        if (nameSocial.description==="Web") {
            return  <div>
                        <span > <a className='btn_enlace' href={urlFinish} target='_blank' rel="noreferrer"><BsLink color='#0e76a8' size={28} /> {nameSocial.name} </a></span>
                        <span className='cantFolowers' > <BsFillPersonFill size={12} color='#292929'/> {converterToFollowers(nameSocial.followers)} </span>
                    </div>
        }       
        return                                                  
    }

    const getUrlSocialMedia = (url_social, username) => {
        if (url_social==="Facebook") {
            if (isDispositivoMovil) {
                return "fb://facewebmodal/f?href=https://www.facebook.com/"+username;
            }else{
                return "https://www.facebook.com/"+username;
            }  
        }
        if (url_social==="Twitter") {
            if (isDispositivoMovil) {
                return "twitter://user?screen_name="+username;
            }else{
                return "https://www.twitter.com/"+username;
            }
        }
        if (url_social==="Instagram") {
            if (isDispositivoMovil) {
                return "instagram://user?username="+username;
            }else{
                return "https://www.instagram.com/"+username+"/";
            }   
        }
        if (url_social==="Tiktok") {
            return "https://www.tiktok.com/@"+username;
        }
        if (url_social==="Youtube") {
            return "https://www.youtube.com/"+username; 
        }
        if (url_social==="Whatsapp") {
            return "https://wa.me/"+username+"?text=Hola, te encontré en Pautty.com"; 
        }
        if (url_social==="Linkedin") {
            return "https://www.linkedin.com/in/"+username    
        }    
        if (url_social==="Telegram") {
            return "https://telegram.im/@"+username    
        }  
        if (url_social==="Pinterest") {
            return "https://pinterest.com/"+username    
        } 
        if (url_social==="Line") {
            return "https://line.me/"+username    
        }    
        if (url_social==="Snapchat") {
            return "https://www.snapchat.com/add/"+username    
        }  
        
        if (url_social==="Web") {
            return "https://"+username    
        }    
        return 
    }

    return (
        <div style={{ width: '100%', height: '100vh', paddingBottom: '10%', background: '#f4f4f4' }}>

        <div className='row' style={{ height: '80%', margin: 0 }}>       

            <div style={{ margin: 0, padding: 0 }} className='col-xl-6 col-lg-6 col-md-6 col-sm-12' onClick={handlewModalImageShow}>
                {/* <video height={'auto'} width={'100%'} autoPlay loop>
                    <source src={"https://statics-gridwel.s3.us-east-2.amazonaws.com/videoPost/1_user_video_post_202308081807463032036050005.mp4"} type="video/mp4" />
                </video> */}
                <img height={'auto'} width={'100%'} src={valla.photo_tv} alt={'Imagen pautty ' + valla.name} />
            </div>

            <div style={{ maxWidth: 800 }} className='col-xl-6 col-lg-6 col-md-6 col-sm-12' >
            
                <div style={{ paddingLeft: 30, paddingRight: 30, height: '100%' }}>
                    
                  <div style={{ margin: 'auto',  width: '100%' }}>
                  <div style={{ paddingTop: 15, paddingBottom: 10, textAlign: 'center', margin: 'auto' }}>
                        {valla.valid_screen ?
                            <h2>{valla.name} {' '}
                                <span title='Verificado por el equipo de Pautty'>
                                    <BsFillPatchCheckFill color='#49ADF4' />
                                </span>
                            </h2>
                            :
                            <h2>{valla.name}</h2>
                        }
                        {valla.username!==''&&
                            <p style={{ color: '#292929' }}>@{valla.username} </p>
                        }

                    {(valla.time_price !== '' && valla.time_price !== undefined && valla.price !== "" && valla.price !== "0") &&
                        <div>
                            <span className='price-card-home'>${dollarIndianLocale.format(valla.price)}</span>
                            <span className='text-price-extra-home'>{config.list_code_plan_payments_pautty[parseInt(valla.time_price)].name}</span>
                        </div>
                    }

                    </div>

                    <div className='row'>

                        <Modal
                            show={showModalImage}
                            dialogClassName="modal-90w"
                            centered
                            onHide={handlewModalImageClose}>
                            <Modal.Header style={{ background: '#dc3545', color: '#fff', textAlign: 'center' }} closeButton>
                                <Modal.Title style={{ width: '100%', textAlign: 'center' }}>{valla.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <img src={valla.photo_tv} alt={valla.name} width={'100%'} />
                            </Modal.Body>
                        </Modal>

                       
                        <div className='col-12' style={{ marginBottom: '30%' }}>
                                {listElementsScreen.length >0 ?
                                    <div style={{ margin: 'auto'}}>
                                        {listElementsScreen.map((item, index)=>{
                                            return <div key={index} className='links-container'>
                                                            <GetIconSocialMedia nameSocial={item} />
                                                    </div>
                                        })} 
                                    </div>
                                    :
                                    <div style={{textAlign:'center'}}> <span>Sin información registrada</span> </div>
                                }

                        </div>
                    </div>
                  </div>


                </div>

            </div>

        </div>

            <ToastContainer />
        </div>
    )

}

export default ShowInfluencer;