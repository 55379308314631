import React from 'react';
import { BsCardChecklist, BsCardImage, BsCollectionPlayFill, BsEye, BsFillPlayCircleFill, BsFillPatchCheckFill, BsFillPenFill, BsFillTrashFill, BsPenFill, BsPencil, BsPlay, BsShare } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { config } from '../../configPautty';
let dollarIndianLocale = Intl.NumberFormat('es-CO');

const ItemCard = ({item, tabShowGetScreens, handleOpenModalSharing, buttonDelete, buttonFull}) => {

    const navigate = useNavigate();
    
    return <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 card-home-content'>
                
                     <div className='card-home-content-body'>
                        <div onClick={()=>{navigate('/'+item.code_checksum)}} className='img-card-home' style={{ backgroundImage: 'url('+item.photo_tv+')', height: window.screen.height/2.7 }}>
                            
                            {((tabShowGetScreens==='valla' || tabShowGetScreens==='valla-digital'))&&
                                <div>
                                    {(item.width_screen_active!=='0' && item.width_screen_active!=='')&&
                                        <div className='content-item-card-sizes'>
                                            <span>{item.width_screen_active}m x {item.height_screen_active}m</span>
                                        </div>
                                    }
                                    {item.address_location!==''&&
                                    <>
                                        <div className='content-item-card-location'>
                                        </div>
                                        <div className='content-item-card-location-span'>
                                            <MdLocationOn color='#ffffff' />
                                            <span>{' '}{item.address_location}</span>
                                        </div>
                                    </>
                                    }
                                </div>
                            }    
                        </div> 

                        <div className='card-home-content-body-text'>

                        {item.owner_company!==''&&
                            <span className='text-company-card-home'>
                                By <b>{item.owner_company}</b>
                            </span>
                        }
                        
                                <div onClick={()=>{navigate('/'+item.code_checksum)}}>
                                {item.valid_screen?
                                    <span title='Validada por el equipo de Pautty'>{item.name} {' '}
                                        <span>
                                            <BsFillPatchCheckFill color='#49ADF4' />
                                        </span>
                                    </span>
                                    :
                                    <span>{item.name}</span>
                                }

                                {(item.username!=='' && tabShowGetScreens==='influencer')&&
                                    <p className='text-price-extra-home'>@{item.username}</p>
                                }
                                {(tabShowGetScreens==='valla' || tabShowGetScreens==='valla-digital')&&
                                    <p className='text-price-extra-home'>{item.type_business}<br></br>
                                        {item.type_service_app === 'valla-digital'?
                                            <span style={{ background: '#19a7a4', color: '#fff', padding: '3px 28px 3px 28px', borderRadius: 5 }}>Valla Digital</span>
                                            :
                                            <span style={{ background: '#dcdcdc', color: '#fff', padding: '3px 28px 3px 28px', borderRadius: 5 }}>Valla No Digital</span>
                                        }
                                    </p>
                                }

                                {/* {(item.time_price !== '' && item.time_price !== undefined && item.price !== "" && item.price !== "0") ?
                                    <div>
                                        <span className='price-card-home'>${dollarIndianLocale.format(item.price)}</span>
                                        <span className='text-price-extra-home'>{config.list_code_plan_payments_pautty[parseInt(item.time_price)].name}</span>
                                    </div>
                                    :
                                    <div>
                                        <span className='text-price-extra-home'>Precio sin especificar</span>
                                    </div>
                                } */}
                                
                            </div>
                            

                         <div className='row items-icons-bottom-home-card-content'>
                                 
                                 {/* <div className='col-6 items-icons-bottom-home-card'>
                                     <button style={{ border: 'none', background: 'transparent' }}><p style={{ color: '#9a9a9a' }}> <BsSearch /> </p></button>
                                 </div> */}
                                 <div className='col-6 items-icons-bottom-home-card'>
                                     <button style={{ border: 'none', background: 'transparent', zIndex: 999 }} onClick={()=>{handleOpenModalSharing(item.code_checksum, item.name)}}><p style={{ color: '#9a9a9a' }}>  <BsShare /></p></button>
                                 </div>
                                 <div className='col-6 items-icons-bottom-home-card'>
                                    {/* <button style={{ border: 'none', background: 'transparent', zIndex: 999 }} onClick={()=>{navigate('/'+item.code_checksum)}}><p style={{ color: '#9a9a9a' }}> <BsEye /> Ver</p></button>*/}
                                    <button style={{ background: '#F7CF57', color: '#292929', border: 'none', borderRadius: 10, padding: '5px 15px 5px 15px' }} onClick={()=>{buttonFull();navigate('/play/'+item.code_checksum);}}>PLAY <BsFillPlayCircleFill /></button>
                                 </div>
                             </div>

                             {buttonDelete&&
                                <div className='row content-buttons-item-card'>
                                    <div className='col-6'>
                                        <button className='btn btn-warning' onClick={()=>{navigate('/pautas/'+item.code_checksum)}}><BsCardChecklist />{' '}<b>Pautas</b></button>
                                    </div>
                                    <div className='col-3'>
                                        <button className='btn btn-info' onClick={()=>{navigate('/editar-medio-publicitario/'+item.code_checksum)}}><BsPencil />{' '}</button>
                                    </div>
                                    <div className='col-3'>
                                        <button className='btn btn-danger' onClick={()=>{buttonDelete(item.screen_id)}}><BsFillTrashFill />{' '}</button>
                                    </div>
                                </div>
                            }

                        </div>
                     </div>
               </div>
}

export default ItemCard;