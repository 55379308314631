import React from 'react';
import { useNavigate, } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";

const HeaderBack = ({urlBack, title, needLogin}) => {
    const navigate = useNavigate();    
    return (
        <div className="top-to-breadcrumb">
            <div className="left top--back-to-home">
                <button onClick={() => {
                    if (urlBack!=='') {
                        navigate(urlBack)
                    }else{
                        // navigate(-1);
                        window.history.back();
                    }
                    }}>
                    <BsArrowLeftShort />
                </button>
            </div>
            <div className="title text-center">
                <h2>{title}</h2>
            </div>
        </div>
    )
}

export default HeaderBack;