import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { deleteCookies } from '../deleteCookies';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../configPautty';
import { useCookies } from 'react-cookie';
import HeaderBack from '../general/HeaderBack';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { Form } from 'react-bootstrap';
import PautaItem from '../valla/PautaItem';

const ListadoPautas = () => {
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();
    let { code_screen } = useParams();
    const [screen, setScreen] = useState();

    const [loading, setLoading] = useState(true);
    const [loadingPautas, setLoadingPautas] = useState(true);
    
    const [initStart, setInitStart] = useState(true);
    const [itemScreen, setItemScreen] = useState();

    const [postScreen, setPostScreen] = useState([]);

    const [typePost, setTypePost] = useState('menu');
    const [titlePost, setTitlePost] = useState();
    const [buttonActiveModal, setButtonActiveModal] = useState(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        if (initStart) {
            getScreensById();
            setInitStart(false);
            // GetUserPremiumMessage();
        }
    }, [initStart]);

    const getScreensById = () => {
        setLoading(true);
        fetch(config.urlApi + '/api/v1/screen/' + code_screen, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            }
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status === 404) {
                    showToastError(data.message);
                } else {
                    if (data.status === 401) {
                        deleteCookies();
                        setCookies('pathBefore', window.location.pathname, { path: '/' });
                        navigate('/login');
                    }
                    if (!data.status) {
                        setItemScreen(data.screen);
                        setScreen(data.screen.screen_id);
                        getListPostScreen(data.screen.screen_id, '', true);
                        if (data.screen.active === false) {
                            handleSubmitActiveScreen(data.screen.screen_id);
                        }
                        setLoading(false);
                    } else {
                        showToastError(data.message);
                        setLoading(false);
                    }
                }
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
            });
    }

    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const showToastSuccess = (message) => {
        toast.success(message, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const handleChangeTitle = (event) => {
        if (event.target.value.length > 0) {
            setButtonActiveModal(false);
        } else {
            setButtonActiveModal(true);
        }
        if (event.target.value.length <= 40) {
            setTitlePost(event.target.value);
        }
    }

    const handleSubmitActiveScreen = (screenActive) => {
        fetch(config.urlApi + '/api/v1/active-screen/' + screenActive, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            },
            body: JSON.stringify({
                // name: titlePost,
                // type_post: typePost,
                // screen_id: parseInt(screen),
                // originator_post: 'L'
            })
        })
            .then((response) => response.json())
            .then(data => {
                console.log(data);
                if (data.status === 200) {
                    showToastSuccess("Tu contenido ha sido publicado.");
                } else {
                    showToastError(data.message);
                }
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }


    const handleSubmitAddInitPostScreen = event => {
        event.preventDefault();
        setLoading(true);
        if (titlePost.length === 0) {
            showToastError("El títuo no puede estar vacío.");
            setLoading(false);
            return 0;
        }
        fetch(config.urlApi + '/api/v1/init-post-screen/', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            },
            body: JSON.stringify({
                name: titlePost,
                type_post: typePost,
                screen_id: parseInt(screen),
                originator_post: 'L',
                option_style: 1,
                description: 'Descripción de tu publicación'      
            })
        })
            .then((response) => response.json())
            .then(data => {
                if (!data.status) {
                    setTitlePost('');
                    navigate('/editar-pantalla/' + screen + '/editar-contenido/' + data);
                } else {
                    showToastError(data.message);
                }
                setLoading(false);
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                setLoading(false);
                return e;
            });
    }

    const [userPremiumMessage, setUserPremiumMessage] = useState(false);

    // const GetUserPremiumMessage = () => {
    //     fetch(config.urlApi + '/api/v1/user-premium-message/' , {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': `Bearer ${cookies.access_token}`
    //         }
    //     })
    //         .then((response) => response.json())
    //         .then(data => {
    //             if (data){
    //                 setUserPremiumMessage(data.premium_messages);
    //             }
    //         })
    //         .catch(e => {
    //             return e;
    //         });
    // }

    const [pageCurrent, setPageCurrent] = useState(0);
    const [lastResults, setLastResults] = useState(true);
    const [statusPauta, setStatusPauta] = useState('');
    

    const getListPostScreen = (screen, status, increment) => {
        setLoadingPautas(true);
        var urlFetch = "";
        if (!increment) {
            urlFetch = config.urlApi + '/api/v1/get-post-screen-partner/' + screen + '?page=' + 0 + '&status=' + status;
        }else{
            urlFetch = config.urlApi + '/api/v1/get-post-screen-partner/' + screen + '?page=' + pageCurrent + '&status=' + status;
        }
        fetch(urlFetch, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            }
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status) {
                    if (data.status===404) {
                        setLastResults(false);
                    }
                }else{
                    if (pageCurrent === 0) {
                        setPostScreen(data);
                    }else{
                        setPostScreen(pauta => pauta.concat(data));
                    }
                }
                if (increment) {
                    setPageCurrent(pageCurrent + 1);   
                }
                setLoadingPautas(false);
            })
            .catch(e => {
                setLoadingPautas(false);
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    const handleChangeValueStatusPauta = (status) => {
        setPostScreen([]);
        setStatusPauta(status);
        getListPostScreen(screen, status, false);
        setLastResults(true);
    }


    return (
        <div className="cart-wrapper-content" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '65px', maxWidth: 1200, justifyContent: 'center', justifyItems: 'center', margin: 'auto', background: '#fff'}}>

            {loading ?
                <HeaderBack urlBack={'/partner/'} title={'Pautas'} needLogin={true} />
                :
                <HeaderBack urlBack={'/partner/'} title={'Pautas en : ' + itemScreen.name} needLogin={true} />
            }

            {loading&&
                <SpinnerLoadingFixed />
            }

                <>
                    {postScreen &&
                        <div className='' style={{ height: 'auto' }}>
                                                      
                            <div style={{ height: 'auto', margin: 'auto', justifyContent: 'center', justifyItems: 'center', textAlign: 'center', maxWidth: 800 }}>
                            
                            <div style={{ margin: 'auto', background: '#eaeaea', borderRadius: 10 }}>
                                <div className='tabs-medios tabs-medios-pautas' style={{ textAlign: 'center', overflowX: 'scroll' }}>
                                    <div className="radio-filters">
                                        <label htmlFor="todos-switch">Todos</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="todos-switch"
                                            defaultChecked="true"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('');setPageCurrent(1);}}
                                        />
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='registrado-switch'>Registrados</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="registrado-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('0');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='recibidos-switch'>Recibidos</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="recibidos-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('1');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='pagados-switch'>Pagados</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="pagados-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('2');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='aprobados-switch'>Aprobados</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="aprobados-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('3');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='en-proceso-switch'>En proceso</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="en-proceso-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('4');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='publicados-switch'>Publicados</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="publicados-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('5');setPageCurrent(1);}}
                                        />
                                        <hr className="hr-radio-filters"></hr>
                                    </div>
                                    <div className="radio-filters">
                                        <label htmlFor='finish-switch'>Finalizado</label>
                                        <Form.Check
                                            type="radio"
                                            name="group-status"
                                            id="finish-switch"
                                            // checked={valla.available}
                                            onClick={()=>{handleChangeValueStatusPauta('6');setPageCurrent(1);}}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            {postScreen.length>0?
                            <>
                                {(itemScreen.type_service_app==="valla" || itemScreen.type_service_app==="valla-digital")&&
                                    <span> Valla de <b>{itemScreen.width_screen_active} metros</b> x <b>{itemScreen.height_screen_active} metros</b></span>
                                }
                                <p>Listado de Pautas</p>

                                {postScreen.map((item, index) => {
                                    item.type_service_app = itemScreen.type_service_app;
                                    return <PautaItem key={index} item={item} index={index} screen={screen} setPostScreen={setPostScreen} valla={itemScreen} userPremiumMessage={userPremiumMessage} isPartner={true} />
                                })}


                                {loadingPautas ?
                                    <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                                        <h1 className="text-login-title" style={{ margin: 'auto' }}>
                                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                                        </h1>
                                    </div>
                                    :
                                    <div>
                                        {lastResults&&
                                            <button onClick={()=>{getListPostScreen(screen, statusPauta, true);}} className="button-12" style={{ cursor: 'pointer', border: 'none', borderRadius: 15, marginBottom: 50 }}>
                                                Cargar más
                                            </button>
                                        }
                                    </div>
                                }

                            </>
                            :
                                <div style={{ width: '100%', textAlign: 'center', paddingTop: 10 }}>
                                    <BsFillInfoCircleFill color='#838080' size={50} />
                                    <h3 style={{ paddingTop: 10, color: '#838080' }}>No tienes Pautas</h3>
                                </div>
                            }
                                
                            </div>


                        </div>
                    }

                </>
           

                {loadingPautas &&
                    <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                        <h1 className="text-login-title" style={{ margin: 'auto' }}>
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </h1>
                    </div>
                }



            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Agregar Pauta
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="edit-pautty-form">
                        <form onSubmit={handleSubmitAddInitPostScreen}>
                            <input onChange={handleChangeTitle} className='input-form-pautty' type="text" name="name" placeholder="Título de tu contenido" value={titlePost} maxLength={40} />
                            <br></br>
                            <button disabled={buttonActiveModal} className="btn btn-default color-bottons btn-lg w-100" type="submit">
                                Agregar
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <ToastContainer />

        </div>
    )
}

export default ListadoPautas;