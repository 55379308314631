import React, { useState } from 'react';
import { BsFacebook, BsLinkedin, BsTelegram, BsTwitterX, BsWhatsapp } from 'react-icons/bs';

const ButtonShared = ({id_link, title, type_shared}) => {

    var url_share_social = "";
    if (type_shared==='pauta') {
        url_share_social = "https://pautty.com/pauta/";
    }else if (type_shared==='valla') {
        url_share_social = "https://pautty.com/";
    }

        
    return (
        <div className='row'>
            <a className='col-3 url-update-links' target='_blank' rel='noreferrer' 
                href={"https://www.facebook.com/sharer/sharer.php?u="+ url_share_social + id_link}><BsFacebook size={30} color='#3b5998' />
            </a>
            <a className='col-3 url-update-links' target='_blank' rel='noreferrer' 
                href={"https://twitter.com/intent/tweet?text=" + title + "&url="+ url_share_social + id_link}><BsTwitterX size={30} color='#000000' />
            </a>
            <a className='col-3 url-update-links' target='_blank' rel='noreferrer' 
                href={"https://api.whatsapp.com/send?text=" + title + ". "+ url_share_social + id_link}><BsWhatsapp size={30} color='#25d366' />
            </a>
            <a className='col-3 url-update-links' target='_blank' rel='noreferrer' 
                href={"https://www.linkedin.com/sharing/share-offsite/?url="+ url_share_social + id_link}><BsLinkedin size={30} color='#0e76a8' />
            </a>
            <a className='col-3 url-update-links' target='_blank' rel='noreferrer' 
                href={"https://t.me/share/url?url="+ url_share_social + id_link + "&text=" + title}><BsTelegram size={30} color='#0088cc' />
            </a>
        </div>
    )
}

export default ButtonShared;