import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FcHome, FcAdvertising, FcSearch, FcStatistics, FcVoicePresentation, FcManager } from "react-icons/fc";
import { useCookies } from 'react-cookie';
import { BsTv } from 'react-icons/bs';
import { AiFillNotification } from 'react-icons/ai';
import { MdLocationPin } from 'react-icons/md';

const NavBarFooter = () => {
    const [cookies] = useCookies(['user']);
    const [showMenuButtonCenter, setShowMenuButtonCenter] = useState(false);

    const handleShowMenuButtonCenter = () => {
        setShowMenuButtonCenter(!showMenuButtonCenter);
    }

    return (
        <div className='responsiveNavBarFooter'>
            
            <footer className="footer-nav-wrapper">
                <div className="container">
                    <div className="footer-nav-menu">
                      
                        <Link className={window.location.pathname==="/" ? "footMenu active":"footMenu"} to="/">
                            <i><FcHome/></i>
                            <span>Inicio</span>
                        </Link>

                        {/* {!showMenuButtonCenter ?
                            <button className='button-plus-center' onClick={()=>handleShowMenuButtonCenter(true)}>
                                <BsFillPlusCircleFill color='#F7CF57' size={55} />
                            </button>
                            :
                            <button className='button-plus-center' onClick={()=>handleShowMenuButtonCenter(true)}>
                                <AiFillCloseCircle color='#dc3545' size={55} />
                            </button>
                        } */}
                        
                        
                        <Link className={window.location.pathname==="/mapa" ? "footMenu active":"footMenu"} to="/mapa">
                            <i><MdLocationPin color='#dc3545' /></i>
                            <span>Mapa</span>
                        </Link>

                        <Link className={window.location.pathname==="/mis-pautas" ? "footMenu active":"footMenu"} to="/mis-pautas">
                            <i><FcAdvertising/></i>
                            <span>Pautas</span>
                        </Link>

                        {/* <Link className={window.location.pathname==="/mi-perfil" ? "footMenu active":"footMenu"} to="/mi-perfil">
                            <i><FcManager /></i>
                            <span>Perfil</span>
                        </Link> */}

                        {/* <Link className={window.location.pathname==="/promociones" ? "footMenu active":"footMenu"} to="/promociones">
                            <i><FcBullish/></i>
                            <span>Análisis</span>
                        </Link> */}

                    {showMenuButtonCenter&&
                        <div className='modal-options-button-center animate__animated animate__bounceInUp'>
                            <div className='search-tags' style={{ width: '100%', padding: 25 }}>
                                <Link to="/mis-pantallas"><i><BsTv /></i> Mis Pantallas</Link>
                            </div>
                            <div className='search-tags' style={{ width: '100%', padding: 25 }}>
                                <Link to="/my-posts"><i><AiFillNotification /></i> Pautar</Link>
                            </div>
                        </div>
                    }
                        
                    </div>
                </div>
            
            </footer>

            {showMenuButtonCenter&&
                <a onClick={()=>handleShowMenuButtonCenter(true)} className='background-menu-center'></a>
            }

        </div>
    )
}

export default NavBarFooter;