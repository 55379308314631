//import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  } from "react-router-dom";

import Login from './authentication/Login';
import Confirm from './authentication/Confirm';
import Home from './home/Home';
import Mapa from './home/Map';
import { Terms } from './general/legal/Terms';
import { About } from './general/legal/About';
import { Polity } from './general/legal/Polity';
import { PQR } from './general/legal/PQR';

import 'mapbox-gl/dist/mapbox-gl.css';
import Pauta from './valla/Pauta';
import NotFoundPage from './general/NotFoundPage';
import { Tutorials } from './home/Tutorials';
import MisPauttas from './valla/MisPautas';
import MiValla from './profile/MiValla';
import Show from './valla/show/Show';
import Studio from './studio-pautty/Studio';
import HomePartner from './partner/HomePartner';
import UpdateValla from './partner/UpdateValla';
import ListadoPautas from './partner/ListadoPautas';
import Play from './partner/Play';
// import { MyNotifications } from './profile/Notifications';


function App() {
  return (
    <Router>
    <div>

      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/login-confirm' element={<Confirm />} />
        <Route path='/' element={<Home/>} />
        <Route path='/mapa' element={<Mapa/>} />
        <Route path='/pqr' element={<PQR />} />

        {/* <Route path='/chat/:post' element={<Chat />} />
        <Route path='/mensajes' element={<Messages />} /> */}
        
        <Route path='/mis-pautas' element={<MisPauttas />} />
        
        <Route path='/mi-valla' element={<MiValla/>} />
        <Route path='/editar-pantalla/:screen/editar-contenido/:post' element={<Studio />} />
        <Route path='/pauta/:post' element={<Pauta />} />
        
        <Route path='/terms' element={<Terms />} />
        <Route path='/about' element={<About />} />
        <Route path='/privacy-policies' element={<Polity />} />

        {/* <Route path='/notificaciones' element={<MyNotifications />} /> */}
        
        <Route path='/:code_checksum' element={<Show />} />
        <Route path='/play/:code_checksum' element={<Play />} />
        <Route path='/tutoriales/como-empezar-a-usar-pautty' element={<Tutorials />} />


        {/* --------------------------------------------------------------------------------------Partner */}
        <Route path='/partner/' element={<HomePartner />} />
        <Route path='/editar-medio-publicitario/:screen' element={<UpdateValla />} />  
        <Route path='/pautas/:code_screen' element={<ListadoPautas />} />

        <Route path='*' element={<NotFoundPage />} />
        
      </Routes>
    </div>
  </Router>
  );
}

export default App;
